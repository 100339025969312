<template>
  <!-- 路外停车场 ————> 计费配置 -->
  <div class="box-card">
    <div class="content_box" style="text-align: left;">
      <div class="title">已配置【{{ parking_name }}】</div>
      <el-divider></el-divider>
      <!-- 表格 -->
      <el-table class="config_box" ref="configListTable" :data="configList" align="center" header-align="center"
        tooltip-effect="dark" style="width: 100%" height="240" border stripe>
        <!-- <el-table-column type="index"  width="50"></el-table-column> -->
        <!-- card_type(卡类型) 1:计时卡 2:月租卡 -->
        <!-- <el-table-column prop="card_name" label="卡类型"></el-table-column> -->
        <el-table-column prop="scheme_name" label="计费方案名称"></el-table-column>
        <el-table-column label="方案类型">
          <template slot-scope="scope">
            <div>
              {{ scope.row.scheme_str
              }}{{ scope.row.is_default ? "【默认方案】" : "" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="方案详情" min-width="260" id="changetest">
          <template slot-scope="scope">
            <div v-html="scope.row.scheme_details.replace(/\r\n|\n|\r/g, '<br/>')"></div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <lbButton type="err" icon="xqpz" hint="取消配置" :disabled="isDisabled" @click="throttle(scope.row, 2)"
              v-preventReClick></lbButton>
            <!-- <lbButton
                icon="shezhi_moren"
                hint="取消默认"
                :disabled="isDisabled"
                @click="throttle(scope.row, 4)"
                v-if="
                  scope.row.is_default &&
                    (scope.row.scheme_type == 1 ||
                      scope.row.scheme_type == 2 ||
                      scope.row.scheme_type == 3)
                "
                v-preventReClick
              ></lbButton> -->
            <lbButton type="info" icon="moren" hint="设为默认" :disabled="isDisabled" @click="throttle(scope.row, 3)" v-if="!scope.row.is_default &&
              (scope.row.scheme_type == 1 ||
                scope.row.scheme_type == 2 ||
                scope.row.scheme_type == 3)
              " v-preventReClick></lbButton>
          </template>
        </el-table-column>
      </el-table>
      <div class="title">未配置</div>
      <el-divider></el-divider>
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="方案名称：">
          <el-input maxlength="20" v-model="queryForm.scheme_name" @keyup.enter.native="onSubmit"
            @input="e => (queryForm.scheme_name = validSpace(e))" placeholder="请输入方案名称"></el-input>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <el-table class="config_box" ref="noConfigListTable" :data="noConfigList" align="center" header-align="center"
        tooltip-effect="dark" style="width: 100%" border stripe height="240">
        <!-- <el-table-column prop="card_name" label="卡类型"></el-table-column> -->
        <el-table-column prop="scheme_name" label="计费方案名称"></el-table-column>
        <el-table-column prop="scheme_str" label="方案类型"></el-table-column>
        <el-table-column label="方案详情" min-width="260">
          <template slot-scope="scope">
            <div v-html="scope.row.scheme_details.replace(/\r\n|\n|\r/g, '<br/>')"></div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <lbButton type="setting" icon="jfpeizhi" hint="配置" :disabled="isDisabled" @click="throttle(scope.row, 1)"
              v-preventReClick></lbButton>
          </template>
        </el-table-column>
      </el-table>
    </div>
      <!-- 页码 -->
      <div class="footControlBox">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="noConfigTotal">
        </el-pagination>
        <div class="buttonBox">
        <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
        <!-- <lbButton type="default" fill icon="confirm" class="checkButton" @click="defaultForm">保 存</lbButton> -->
      </div>
      </div>
  </div>
</template>

<script>
import {
  getParkingCostNotList,
  getParkingCostConfigList,
  setlnParkingCost
} from '@/api/curbParkapi'
import { setOutParkingCost } from '@/api/outParking'
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
  props: ['parking_name', 'parkingId'],
  data () {
    return {
      // 查询表单
      queryForm: {
        scheme_name: '',
        PageSize: 10,
        PageIndex: 1
      },
      noConfigTotal: 0,
      // 已配置
      configList: [],
      // 待配置
      noConfigList: [],
      // parkingId: '',
      // parking_name: '',
      // 是否禁用配置按钮
      isDisabled: false,
      is_default: false,
      lastTime: 0
    }
  },
  created () {
    this.fnGetParkingCostNotList()
    this.fnGetParkingCostList()
  },
  mounted () { },
  computed: {
    ...mapState('menuList', ['isRoutW'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    throttle (row, type) {
      var now = new Date().valueOf()
      if (this.lastTime === 0) {
        // console.log('触发事件');
        this.isDisabled = true
        this.lastTime = now
        this.fn(row, type)
      } else {
        if (now - this.lastTime > 1000) {
          this.lastTime = now
          this.isDisabled = true
          // console.log('间隔大于3秒，触发方法');
          this.fn(row, type)
        } else {
          console.log('不触发')
        }
      }
    },
    fn (row, type) {
      // 1：配置 2：取消配置 3：默认 4：取消默认
      if (type === 1) {
        this.setConfig(row)
      } else if (type === 2) {
        this.cancelConfig(row)
      } else if (type === 3) {
        this.setDefault(row)
      } else if (type === 4) {
        this.cancelDefault(row)
      }
    },
    //   获取停车场未绑定的计费规则列表
    async fnGetParkingCostNotList () {
      // console.log(this.isDisabled);
      // console.log(this.parkingId);
      const res = await getParkingCostNotList({
        parking_id: this.parkingId,
        scheme_name: this.queryForm.scheme_name,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex
      })
      this.isDisabled = false
      // console.log(this.isDisabled);
      // console.log(res.Data.DataList);
      this.noConfigList =
        res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.noConfigTotal =
        res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetParkingCostNotList()
    },
    // 页码改变
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryForm.PageIndex = val
      this.fnGetParkingCostNotList()
      this.$refs.noConfigListTable.bodyWrapper.scrollTop = 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetParkingCostNotList()
    },
    // 获取停车场已绑定的计费规则列表
    async fnGetParkingCostList () {
      const res = await getParkingCostConfigList({
        parking_id: this.parkingId
      })
      // console.log(res);
      this.isDisabled = false
      this.configList = res && res.Code === 200 && res.Data ? res.Data : []
    },
    // 配置
    setConfig (row) {
      // console.log(this.configList);
      var isDefault = false
      var arr = this.configList.filter(item => {
        return (
          item.scheme_type !== 4 && item.scheme_type !== 10 && item.is_default
        )
      })
      // console.log(arr);
      if (arr.length <= 0 && row.scheme_type !== 4 && row.scheme_type !== 10) {
        isDefault = true
      }
      // console.log(isDefault);
      var obj = {
        parking_id: this.parkingId,
        cost_scheme_id: row.cost_scheme_id,
        // card_type(卡类型) 1:计时卡 2:月租卡
        card_type: row.card_type,
        // scheme_type(方案类型) 1 : 计时 2 : 计次 3 : 时段 4 : 车型 10: 月租
        scheme_type: row.scheme_type,
        is_default: isDefault,
        // type_operator（操作类型） 1：配置 2：取消配置 3：默认 4：取消默认
        operator_type: 1
      }
      // console.log(obj);
      this.fnsetlnParkingCost(obj)
    },
    // 取消配置
    cancelConfig (row) {
      this.is_default = row.is_default
      // console.log(row);
      var obj = {
        parking_id: this.parkingId,
        cost_scheme_id: row.cost_scheme_id,
        // card_type(卡类型) 1:计时卡 2:月租卡
        card_type: row.card_type,
        // scheme_type(方案类型) 1 : 计时 2 : 计次 3 : 时段 4 : 车型 10: 月租
        scheme_type: row.scheme_type,
        is_default: false,
        // operator_type（操作类型） 1：配置 2：取消配置 3：默认 4：取消默认
        operator_type: 2
      }
      // console.log(obj);
      this.fnsetlnParkingCost(obj)
    },
    // 取消默认
    cancelDefault (row) {
      // console.log(row);
      var arr = this.configList.filter(item => {
        return (
          item.scheme_type !== 4 && item.scheme_type !== 10 && !item.is_default
        )
      })
      console.log(arr)
      if (arr.length > 0) {
        this.setDefault(arr[0])
      } else {
        var obj = {
          parking_id: this.parkingId,
          cost_scheme_id: row.cost_scheme_id,
          // card_type(卡类型) 1:计时卡 2:月租卡
          card_type: row.card_type,
          // scheme_type(方案类型) 1 : 计时 2 : 计次 3 : 时段 4 : 车型 10: 月租
          scheme_type: row.scheme_type,
          is_default: false,
          // operator_type（操作类型） 1：配置 2：取消配置 3：默认 4：取消默认
          operator_type: 4
        }
        // console.log(obj);
        this.fnsetlnParkingCost(obj)
      }
    },
    // 设为默认
    setDefault (row) {
      var obj = {
        parking_id: this.parkingId,
        cost_scheme_id: row.cost_scheme_id,
        // card_type(卡类型) 1:计时卡 2:月租卡
        card_type: row.card_type,
        // scheme_type(方案类型) 1 : 计时 2 : 计次 3 : 时段 4 : 车型 10: 月租
        scheme_type: row.scheme_type,
        is_default: true,
        // operator_type（操作类型） 1：配置 2：取消配置 3：默认 4：取消默认
        operator_type: 3
      }
      // console.log(obj);
      this.fnsetlnParkingCost(obj)
    },
    // 停车场计费规则配置请求
    // 判断是路内还是路外
    async fnsetlnParkingCost (obj) {
      var res
      if (this.isRoutW) {
        res = await setOutParkingCost(obj)
      } else {
        res = await setlnParkingCost(obj)
      }
      // console.log(res);
      if (res && res.Code === 200) {
        if (res.Message !== '操作成功') {
          this.$msg.warning(res.Message)
        }
        this.queryForm.PageIndex = 1
        this.fnGetParkingCostNotList()
        this.fnGetParkingCostList()
      } else {
        this.isDisabled = false
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

<style scoped lang="less">
.title {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.config_box {
  /deep/ .el-table__body-wrapper {
    overflow-y: auto;
    max-height: 220px !important;
  }
}

.box-card {
  position: relative;
  // padding-bottom: 5px;
  margin-top: 0 !important;

  .footControlBox{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  // .checkButton {
  //   position: absolute;
  //   right: 50px;
  // }

  // .checkButton2 {
  //   position: absolute;
  //   right: 150px;
  // }
}

/* .ul_box>li{
          width: 200px;
          height: 50px;
          background-color: #aaa;
          padding: 0 25px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 2px solid #fff;
      } */
</style>
