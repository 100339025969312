<template>
  <!-- 路外--通行策略 -->
  <div class="box-card" style="text-align: left">
    <div class="cenue">
      <div class="left">
        <div class="deciveList">{{ parkName }}</div>
        <div class="left-top">
          <lbButton size="small" icon="tingchechang1" type="default"
            @click="addNode(2)">新增子停车场</lbButton>
          <lbButton size="small" icon="road" type="succes" @click="addNode(3)">新增出入口</lbButton>
        </div>
        <div class="left-tree">
          <el-tree :expand-on-click-node="false" :data="treeList" ref="myTree" :props="defaultProps"
            @node-click="handleNodeClick" node-key="id" :current-node-key="currentNodeKey" :highlight-current="true"
            default-expand-all>
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span :style="{
                fontSize: data.type === 2 ? '16px' : '15px',
                color:
                  data.id === selectNode
                    ? '#409EFF'
                    : data.type === 2
                      ? '#000'
                      : '#606266',
                fontWeight: data.type === 2 ? '700' : '400',
              }">
                <i
                  :class="data.type === 2 ? 'iconfont lebo-parkplace' : data.cameraType === 2 ? 'iconfont lebo-inoutport' : 'iconfont lebo-inoutport-in'"></i>
                {{ node.label }}
              </span>
              <span class="right_box">
                <i v-if="data.id !== parkId" class="icon iconfont lebo-bianji" style="margin-right: 10px"
                  @click.prevent.stop="editNode(node, data)"></i>
                <i v-if="data.id !== parkId" class="icon iconfont lebo-shanchu1"
                  @click.prevent.stop="removeNode(node, data)"></i>
              </span>
            </span>
          </el-tree>
        </div>
      </div>
      <el-divider direction="vertical" class="vertical_devider"></el-divider>
      <div class="right">
        <div v-if="rightPage === 2">
          <el-form label-width="120px">
            <el-form-item label="车位数：">
              <el-checkbox-group v-model="parkingrules.parkinglot_type">
                <el-checkbox :label="1">计时卡不计</el-checkbox>
                <el-checkbox :label="2">月租卡不计</el-checkbox>
                <el-checkbox :label="3">储值卡不计</el-checkbox>
                <el-checkbox :label="4">贵宾卡不计</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="计费规则：">
              停车费支付成功后，
              <el-input size="medium" style="width: 100px; margin: 0 5px" class="small-input" maxlength="5"
                v-model="parkingrules.minute"
                @input="(e) => (parkingrules.minute = defaultsZero(e))"></el-input>分钟内出场不额外计费
            </el-form-item>
            <el-form-item label="通行规则：">
              <div style="display: flex; flex-direction: column">
                <el-checkbox v-model="parkingrules.parkTrafficRule.issecond">
                  同进同出
                  <el-input size="medium" style="width: 100px; margin: 0 5px" class="small-input" maxlength="5"
                    v-model="parkingrules.parkTrafficRule.second" @input="(e) =>
                      (parkingrules.parkTrafficRule.second = defaultsZero(e))
                      "></el-input>秒内，不扫尾牌
                </el-checkbox>
                <el-checkbox v-model="parkingrules.parkTrafficRule.isdiscern">
                  同车道车牌识别差
                  <el-input size="medium" style="width: 100px; margin: 0 5px" class="small-input" maxlength="5"
                    v-model="parkingrules.parkTrafficRule.carsnub" @input="(e) =>
                      (parkingrules.parkTrafficRule.carsnub = defaultsZero(e))
                      "></el-input>位，
                  <el-input size="medium" style="width: 100px; margin: 0 5px" class="small-input" maxlength="5"
                    v-model="parkingrules.parkTrafficRule.carssecond" @input="(e) =>
                    (parkingrules.parkTrafficRule.carssecond =
                      defaultsZero(e))
                      "></el-input>秒内，不重复识别车牌
                </el-checkbox>
                <el-checkbox v-model="parkingrules.parkTrafficRule.autoRelease">无入场记录车辆自动放行</el-checkbox>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="rightPage === 3" class="right3">
          <el-form label-width="120px">
            <span class="title_box">临停车</span>
            <el-divider></el-divider>
            <el-form-item label="通行规则：">
              <el-checkbox v-model="gaterules.temporarycars.NoEntry">禁止通行</el-checkbox>
              <el-checkbox v-model="gaterules.temporarycars.NoParkinglotEntry">车位满时禁止通行</el-checkbox>
            </el-form-item>
            <el-form-item label="通行时段：">
              在
              <el-time-picker size="medium" style="width: 300px; margin: 0 5px" :clearable="false"
                value-format="yyyy-MM-dd HH:mm:ss" format="HH:mm:ss" is-range v-model="timepick" range-separator="至"
                start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围"></el-time-picker>期间可通行
            </el-form-item>
            <span class="title_box">计时卡</span>
            <el-divider></el-divider>
            <el-form-item label="通行规则：">
              <el-checkbox v-model="gaterules.timingcard.NoEntry">禁止通行</el-checkbox>
              <el-checkbox v-model="gaterules.timingcard.NoParkinglotEntry">车位满时禁止通行</el-checkbox>
            </el-form-item>
            <span class="title_box">月租卡</span>
            <el-divider></el-divider>
            <el-form-item label="通行规则：">
              <el-checkbox v-model="gaterules.monthlycard.NoEntry">禁止通行</el-checkbox>
              <el-checkbox v-model="gaterules.monthlycard.NoParkinglotEntry">车位满时禁止通行</el-checkbox>
            </el-form-item>
            <el-form-item label="过期提醒：">
              剩余
              <el-input size="medium" style="width: 100px; margin: 0 5px" class="small-input" maxlength="5"
                v-model="gaterules.monthlycard.ExpirationMsg" @input="(e) => (gaterules.monthlycard.ExpirationMsg = defaultsZero(e))
                  "></el-input>天提醒
            </el-form-item>
            <span class="title_box">储值卡</span>
            <el-divider></el-divider>
            <el-form-item label="通行规则：">
              <el-checkbox v-model="gaterules.storedcard.NoEntry">禁止通行</el-checkbox>
              <el-checkbox v-model="gaterules.storedcard.NoParkinglotEntry">车位满时禁止通行</el-checkbox>
            </el-form-item>
            <el-form-item label="过期提醒：">
              余额少于
              <el-input size="medium" style="width: 100px; margin: 0 5px" class="small-input" maxlength="5"
                v-model="gaterules.storedcard.ExpirationMsg" @input="(e) => (gaterules.storedcard.ExpirationMsg = defaultsZero(e))
                  "></el-input>元提醒
            </el-form-item>
            <span class="title_box">贵宾卡</span>
            <el-divider></el-divider>
            <el-form-item label="通行规则：">
              <el-checkbox v-model="gaterules.vipcard.NoEntry">禁止通行</el-checkbox>
              <el-checkbox v-model="gaterules.vipcard.NoParkinglotEntry">车位满时禁止通行</el-checkbox>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <lebo-dialog :title="type === 2 ? '新增子停车场' : '新增出入口'" :isShow="addChildDialog" width="30%" @close="resetForm"
      footerSlot>
      <el-form :model="chidlrenNodeFrom" ref="addDialogForm" label-width="120px" @submit.native.prevent>
        <el-form-item prop="childNodeName" label="上一级：">
          <!-- <el-input maxlength="30" v-model="selectNodeName" disabled></el-input> -->
          {{ selectNodeName }}
        </el-form-item>
        <el-form-item prop="childNodeName" :label="type === 2 ? '子停车名称：' : '出入口名称：'" :rules="[
          {
            required: true,
            message: type === 2 ? '请输入子停车场名称' : '请输入出入口名称',
            trigger: 'blur',
          },
        ]">
          <el-input maxlength="30" :placeholder="type === 2 ? '请输入子停车场名称' : '请输入出入口名称'
            " v-model="chidlrenNodeFrom.childNodeName"
            @input="(e) => (chidlrenNodeFrom.childNodeName = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item v-if="type === 3" label="通道类型：">
          <el-select v-model="chidlrenNodeFrom.cameraType" placeholder="请选择出入口">
            <el-option label="出口" value='2'></el-option>
            <el-option label="入口" value='1'></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <lbButton type="orign" icon="confirm" @click="save">保 存</lbButton>
      </span>
    </lebo-dialog>
    <lebo-dialog :title="type === 2 ? '编辑子停车场' : '编辑出入口'" :isShow="isEditDialog" width="30%"
      @close="$refs.editFormRef.clearValidate(), isEditDialog = false" footerSlot>
      <el-form :model="editForm" ref="editFormRef" label-width="120px" @submit.native.prevent>
        <el-form-item prop="parking_name" :label="type === 2 ? '子停车名称：' : '出入口名称：'" :rules="[
          {
            required: true,
            message: type === 2 ? '请输入子停车场名称' : '请输入出入口名称',
            trigger: 'blur',
          },
        ]">
          <el-input maxlength="30" :placeholder="type === 2 ? '请输入子停车场名称' : '请输入出入口名称'
            " v-model="editForm.parking_name" @input="(e) => (editForm.parking_name = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item v-if="type === 3" label="通道类型：">
          <el-select v-model="editForm.cameraType" placeholder="请选择出入口">
            <el-option label="出口" value='2'></el-option>
            <el-option label="入口" value='1'></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <lbButton type="orign" icon="confirm" @click="saveEdit">保 存</lbButton>
      </span>
    </lebo-dialog>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAccessStrategy" v-preventReClick>保 存
    </lbButton>
  </div>
</template>
<script>
import {
  getOutParkingTreeList,
  updateParkChild,
  addChildParking,
  getParkingTrafficRuleDetail,
  uptParkingTrafficRules,
  delParkingInfo
} from '@/api/outParking'
import { mapMutations, mapState } from 'vuex'
export default {
  props: ['parkId'],
  data () {
    return {
      currentNodeKey: '',
      treeList: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      addChildDialog: false,
      chidlrenNodeFrom: {
        childNodeName: '',
        cameraType: '1',
        entrance: ''
      },
      type: 0,
      rightPage: 2,
      selectNode: null,
      selectNodeName: '', // 上一节点名称
      parkName: '', // 车场名
      parkingrules: {
        // 通行规则配置
        parkinglot_type: [], // 车位数规则 1.计时卡不计  2.月租卡不计 3.储值卡不计 4.贵宾卡不计
        minute: 15, // 计费规则 （）分钟内出场不额外计费
        parkTrafficRule: {
          // 通行策略
          second: 60, // 同进同出（）秒不扫尾排
          issecond: false, // 是否开启同进同出规则
          carsnub: 1, // 同车道车牌识别差（）位
          carssecond: 5, // 秒内，不重复识别车牌
          isdiscern: false, // 是否开启不重复识别车牌规则
          autoRelease: false // 是否开启无入场记录自动放行
        }
      },
      one: 1,
      two: 2,
      three: 3,
      four: 4,
      AccessID: '', // 出入口id
      gaterules: {
        // 临停车
        temporarycars: {
          NoEntry: false, // 是否开启禁止通行
          NoParkinglotEntry: false, // 是否开启车位满时禁止通行
          Begintime: '', // 可通行时段开始时间
          Endtime: '' // 可通行时段结束时间
        },
        timingcard: {
          // 计时卡
          NoEntry: false, // 是否开启禁止通行
          NoParkinglotEntry: false // 是否开启车位满时禁止通行
        },
        monthlycard: {
          // 月租卡
          NoEntry: false, // 是否开启禁止通行
          NoParkinglotEntry: false, // 是否开启车位满时禁止通行
          ExpirationMsg: 7 // 过期提醒：剩余（）天提醒
        },
        storedcard: {
          // 储值卡
          NoEntry: false, // 是否开启禁止通行
          NoParkinglotEntry: false, // 是否开启车位满时禁止通行
          ExpirationMsg: 10 // 过期提醒：余额少于（）元提醒
        },
        vipcard: {
          // 贵宾卡
          NoEntry: false, // 是否开启禁止通行
          NoParkinglotEntry: false // 是否开启车位满时禁止通行
        }
      },
      timepick: ['2020-06-06 00:00:00', '2020-06-06 23:59:59'],
      isEdit: false,
      currentDelete: false,
      alreadyGetParkingInfo: false,
      id: '',
      parking_id: '',
      entranAndExit: [],
      // 编辑子停车场或出入口
      isEditDialog: false,
      editForm: {
        _id: '',
        parking_name: '',
        cameraType: '1',
        entrance: ''
      }
    }
  },
  created () {
    this.initTree()
    this.initRightDetails({ configid: this.parkId })
  },
  mounted () { },
  computed: {},
  watch: {
    parkId (newV, oldV) {
      if (newV !== null && newV.length > 10) {
        this.currentNodeKey = newV
        this.initTree()
        this.initRightDetails({ configid: newV })
      }
    },
    timepick: {
      handler (newV, oldV) {
        if (newV) {
          this.gaterules.temporarycars.Begintime = newV[0]
          this.gaterules.temporarycars.Endtime = newV[1]
          console.log('=-======timepick=', JSON.stringify(newV))
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(['setQueryStatus']),

    judgement (id) {
      const item = this.entranAndExit.find(item => item.AccessID === id)
      console.log('======judgement=', item)
      return item
    },
    addArrayList (item) {
      const index = this.entranAndExit.findIndex(
        little => little.AccessID === item.AccessID
      )
      if (index < 0) {
        this.entranAndExit.push(item)
      } else {
        this.entranAndExit.splice(index, 1, item)
      }
      console.log(
        '=--=-=-==-this.entranAndExit=',
        JSON.stringify(this.entranAndExit)
      )
    },
    async initRightDetails (params) {
      if (this.alreadyGetParkingInfo && this.rightPage === 2) {
        return
      }
      const item = this.judgement(params.configid)
      if (item) {
        this.id = item._id
        this.gaterules = item.gaterules
        this.AccessID = item.AccessID
        this.timepick = [
          item.gaterules.temporarycars.Begintime,
          item.gaterules.temporarycars.Endtime
        ]
        return
      }
      params.type = this.rightPage
      const res = await getParkingTrafficRuleDetail(params)
      if (res.Code === 200) {
        if (this.rightPage === 2) {
          this.parking_id = res.Data._id
          this.alreadyGetParkingInfo = true
          const {
            parkinglot_type,
            minute,
            parkTrafficRule
          } = res.Data.parkingrules
          this.parkingrules.parkinglot_type =
            parkinglot_type != null ? parkinglot_type : []
          this.parkingrules.minute = minute
          this.parkingrules.parkTrafficRule = parkTrafficRule
          var that = this
          setTimeout(() => {
            that.$nextTick(() => {
              that.$refs.myTree.setCurrentKey(this.parkId)
            })
          }, 100)
        } else {
          if (res.Data.gaterules !== null) {
            this.id = res.Data._id
            const { temporarycars } = res.Data.gaterules
            this.gaterules = res.Data.gaterules
            this.AccessID = res.Data.AccessID
            console.log('=-=-=-=-gaterules=', this.gaterules)
            this.timepick = [
              this.validDateTime(temporarycars.Begintime),
              this.validDateTime(temporarycars.Endtime)
            ]
          }
        }
      }
    },
    async initTree () {
      const res = await getOutParkingTreeList({ parkid: this.parkId })
      if (res.Code === 200) {
        this.treeList = res.Data
        this.selectNode = this.parkId
        this.selectNodeName = res.Data[0].title
        this.parkName = res.Data[0].title
        // setTimeout(() => {
        //   this.$nextTick(() => {
        //     this.$refs.myTree.setCurrentKey(this.parkId);
        //   });
        // }, 100);
      }
    },
    // async saveOrEdit(status) {
    //   var obj = {
    //     ParkingID: this.parkId,
    //     parkingrules: this.parkingrules,
    //   };
    //   obj._id = this.parking_id;
    //   if (this.rightPage === 3) {
    //     this.addArrayList({
    //       _id: this.id,
    //       AccessID: this.AccessID,
    //       gaterules: this.gaterules,
    //     });
    //   }
    //   obj.entranAndExit = this.entranAndExit;
    //   console.log("======请求参数====", JSON.stringify(obj));
    //   const res = await uptParkingTrafficRules(obj);
    //   if (res.Code === 200) {
    //     this.setQueryStatus(2);
    //     this.$router.back();
    //   }
    // },
    addNode (t) {
      if (this.selectNode === null) {
        this.$message.warning('请选择节点')
        return
      }
      this.type = t
      this.addChildDialog = true
    },
    async handleNodeClick (data) {
      // console.log('-------------', data)
      if (this.currentDelete || this.selectNode === data.id) {
        return
      }
      if (this.rightPage === 3) {
        this.addArrayList({
          _id: this.id,
          AccessID: this.AccessID,
          gaterules: this.gaterules
        })
      }
      if (data.type === 2) {
        this.rightPage = data.type
        this.currentNodeKey = this.parkId
        this.selectNode = data.id
        this.selectNodeName = data.title
      } else {
        this.currentNodeKey = data.id
        this.rightPage = data.type
        this.selectNode = data.id
        this.selectNodeName = data.title
        await this.initRightDetails({ configid: this.currentNodeKey })
      }
    },
    resetForm () {
      this.chidlrenNodeFrom = {
        childNodeName: '',
        entrance: '',
        cameraType: '1'
      }
      this.addChildDialog = false
      this.$refs.addDialogForm.clearValidate()
    },
    save () {
      console.log('type', this.type)
      let addTypeCamerType = ''
      if (this.type === 2) { // 如果是新增子停车场改为0
        addTypeCamerType = 0
      } else {
        addTypeCamerType = this.chidlrenNodeFrom.cameraType
      }
      this.$refs.addDialogForm.validate(async valid => {
        if (valid) {
          // let entrace = 0
          // if (this.chidlrenNodeFrom.entrance === '') {
          //   entrace = 0
          // }
          const res = await addChildParking({
            ParentId: this.selectNode,
            parking_name: this.chidlrenNodeFrom.childNodeName,
            parktype: this.type, // 停车场类型 1.路内停车场 2.路外停车场 3.路外停车场出入口
            cameraType: addTypeCamerType // 出入口类型
          })
          if (res.Code === 200) {
            this.addChildDialog = false
            this.chidlrenNodeFrom.childNodeName = ''
            this.chidlrenNodeFrom.entrance = ''
            this.chidlrenNodeFrom.cameraType = '1'
            this.selectNode = null
            this.selectNodeName = ''
            this.initTree()
          }
        } else {
          this.chidlrenNodeFrom.cameraType = '1'
          return false
        }
      })
    },
    // 删除树节点
    removeNode (node, data) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          this.currentDelete = true
          console.log('===========removenode=', data)
          const res = await delParkingInfo({ _ids: [data.id] })
          await this.initTree()
          this.currentDelete = false
          this.currentNodeKey = this.parkId
          this.rightPage = 2
          var that = this
          setTimeout(() => {
            that.$nextTick(() => {
              that.$refs.myTree.setCurrentKey(this.parkId)
            })
          }, 100)
        })
        .catch(() => {
          this.$msg.info('已取消删除！')
        })
    },
    // 编辑树节点
    editNode (node, data) {
      this.isEditDialog = true
      var that = this
      this.$nextTick(() => {
        that.editForm._id = data.id
        that.editForm.parking_name = data.title
        // that.editForm.entrace = data.cameraType
        that.editForm.cameraType = data.cameraType.toString()
      })
      this.type = data.type
    },
    saveEdit () {
      this.$refs.editFormRef.validate(async valid => {
        if (valid) {
          const res = await updateParkChild(this.editForm)
          if (res.Code === 200) {
            this.isEditDialog = false
            this.initTree()
          }
        } else {
          return false
        }
      })
    },
    // 保存
    async submitAccessStrategy () {
      var obj = {
        ParkingID: this.parkId,
        parkingrules: this.parkingrules
      }
      obj._id = this.parking_id
      if (this.rightPage === 3) {
        this.addArrayList({
          _id: this.id,
          AccessID: this.AccessID,
          gaterules: this.gaterules
        })
      }
      obj.entranAndExit = this.entranAndExit
      console.log('======请求参数====', JSON.stringify(obj))
      const res = await uptParkingTrafficRules(obj)
      if (res.Code === 200) {
        this.setQueryStatus(2)
        this.$emit('closeDialog', false)
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>
<style scoped lang="less">
.cenue {
  display: flex;
  flex-direction: row;

  /deep/ .left {
    display: flex;
    flex-direction: column;
    flex: 1.5;
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    min-height: 2.08333rem;

    .deciveList {
      width: 100%;
      padding-left: 50px;
      box-sizing: border-box;
      font-size: 18px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #fff;
      height: 60px;
      line-height: 60px;
      background-image: url('~@/assets/img/parkTopbgp.png');
      background-size: 100% 100%;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .left-top {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-top: 8px;
    }

    .left-tree {
      flex: 1;
      margin-top: 8px;
      .custom-tree-node:hover {
        .right_box {
          display: flex;
        }
      }

      .custom-tree-node .right_box {
        display: none;
      }
    }
  }

  // .middle {
  //   width: 1px;
  //   margin-left: 10px;
  //   border-right: solid #ccc 1px;
  //   height: 50vw;
  // }
  /deep/ .right {
    display: flex;
    flex-direction: column;
    flex: 5;
    max-height: 600px;
    overflow-y: auto;

    /deep/.el-form {
      .el-form-item {
        margin-bottom: 10px;

        /deep/.el-form-item__content {
          /deep/.small-input {
            width: 100px;
            margin: 0 5px;
            padding: 0 2px;

            /deep/input {
              text-align: center !important;
            }
          }
        }
      }
    }

    .right3 {
      padding: 0 20px;
    }
  }
}

.vertical_devider {
  height: inherit !important;
  margin: 0 20px !important;
}

.title_box {
  display: inline-block;
  font-size: 17px;
  font-weight: 700;
  color: #000;
  margin-top: 30px;
}

.el-select {
  width: 100%;
}

.box-card {
  position: relative;
  padding-bottom: 30px;
  min-height: 400px;
  margin-top: 0 !important;

  .checkButton {
    position: absolute;
    right: 0px;
    bottom: 0;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
    bottom: 0;

  }
}</style>
