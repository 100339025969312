<template>
  <div class="checkParking_box">
    <!-- 路内/路外停车场 ————> 查看停车场 -->
    <div class="box-card">
      <!-- 主要内容 -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="基本信息">
          <el-form label-width="100px" class="detail_form">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="停车场名称：">{{ DetailsList.parking_name }}</el-form-item>
                <el-form-item label="总车位数：">{{ DetailsList.berth_num }}</el-form-item>
                <el-form-item label="所在位置：">{{ DetailsList.position }}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="默认车牌：" v-if="DetailsList.default_plate_number">{{
                  DetailsList.default_plate_number }}</el-form-item>
              </el-col>
            </el-row>
            <el-form-item>
              <div id="amapall" class="all-amap"></div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <template v-if="inOutPark === 'outPark'">
          <!-- 路外 -->
          <el-tab-pane label="通道信息" name="通道信息">
            <div class="info_box">
              <el-tree :data="outParkingTreeList" :props="defaultProps" default-expand-all :expand-on-click-node="false"
                @node-click="deviceNodeClick">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span class="custom-tree-node-font"
                    :style="data.type == 2 ? ((bindId ? data.id == bindId : data.id == parkingId) ? 'font-weight: 700;color: #000;color:#409EFF;' : 'font-weight: 700;color: #000;') : ((bindId ? data.id == bindId : data.id == parkingId) ? 'color:#409EFF;' : '')">{{
                      node.label }}</span>
                </span>
              </el-tree>
              <el-divider direction="vertical"></el-divider>
              <template v-if="showOutParking">
                <el-table :data="parkingDevidceConfigList" :span-method="objectSpanMethodId" align="center"
                  header-align="center" tooltip-effect="dark" border stripe :key="Math.random()">
                  <el-table-column prop="parkname" label="通道" key="parkname"></el-table-column>
                  <el-table-column prop="cameratype" label="通道类型" key="cameratype"></el-table-column>
                  <el-table-column prop="name" label="通道设备" key="name"></el-table-column>
                  <el-table-column prop="type" label="设备类型" key="type"></el-table-column>
                  <el-table-column prop="position" label="详细位置" key="position"></el-table-column>
                  <el-table-column label="通道码" width="200" key="chakan">
                    <template slot-scope="scope">
                      <lbButton type="succes" icon="chakan" hint="查看" @click="lookOutParkCode(scope.row)"></lbButton>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </div>
          </el-tab-pane>
        </template>
        <template v-if="inOutPark === 'curPark'">
          <!-- 路内 -->
          <el-tab-pane label="车位信息" name="车位信息">
            <div class="icon_box">
              <!-- 查询表单 -->
              <el-form :inline="true" size="small" label-position="right" class="query_form" :model="parkingSpaceData[0]"
                @submit.native.prevent>
                <el-form-item label="车位编号：">
                  <el-input v-model="find_space_number" @keyup.enter.native="onSubmit" placeholder="请输入车位编号"
                    @input="(e) => (find_space_number = validSpace(e))">
                    <i slot="suffix" class="iconfont lebo-sousuo" :fill="true" @click="onSubmit()"></i>
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
            <!-- 图标列表 -->
            <ul class="ul_box">
              <li class="device_li" v-for="(item, index) in parkingSpaceData" :key="index">
                <div class="title">{{ item.space_number }}</div>
                <!-- 地磁设备 -->
                <div class="s0">
                  <div :class="[
                    'geomagnetic',
                    item.geomagnetic_name
                      ? item.geomagnetic_abnormal
                        ? 'geomagnetic_err'
                        : 'geomagnetic_success'
                      : 'geomagnetic_info',
                  ]">
                    <el-tooltip placement="top" effect="light" :style="item.geomagnetic_name ? '' : 'visibility:hidden;'">
                      <div slot="content">
                        设备：{{ item.geomagnetic_name }}<br />状态：<span :style="item.geomagnetic_abnormal
                          ? 'color:red'
                          : 'color:green'
                          ">
                          {{ item.geomagnetic_abnormal ? "异常" : "正常" }}</span>
                      </div>
                      <i class="iconfont lebo-qrsdc"></i>
                    </el-tooltip>
                    <div :class="'circle'" v-if="item.geomagnetic_name"></div>
                  </div>
                  <div :class="[
                    'geomagnetic',
                    item.video_pile_name
                      ? item.video_pile_abnormal
                        ? 'geomagnetic_err'
                        : 'geomagnetic_success'
                      : 'geomagnetic_info',
                  ]">
                    <el-tooltip placement="top" effect="light" :style="item.video_pile_name ? '' : 'visibility:hidden;'"
                      class="video_pile_name_font">
                      <div slot="content">
                        设备：{{ item.video_pile_name }}<br />状态：<span :style="item.video_pile_abnormal ? 'color:red' : 'color:green'
                          ">
                          {{ item.video_pile_abnormal ? "异常" : "正常" }}</span>
                      </div>
                      <i class="iconfont lebo-cpsbspz"></i>
                    </el-tooltip>
                    <div :class="'circle'" v-if="item.video_pile_name"></div>
                  </div>
                  <div :class="[
                    'geomagnetic',
                    item.high_camera_name
                      ? item.high_camera_abnormal
                        ? 'geomagnetic_err'
                        : 'geomagnetic_success'
                      : 'geomagnetic_info',
                  ]">
                    <el-tooltip placement="top" effect="light" class="high_camera_name_font"
                      :style="item.high_camera_name ? '' : 'visibility:hidden;'">
                      <div slot="content">
                        设备：{{ item.high_camera_name }}<br />状态：<span :style="item.high_camera_abnormal
                          ? 'color:red'
                          : 'color:green'
                          ">
                          {{ item.high_camera_abnormal ? "异常" : "正常" }}</span>
                      </div>
                      <i class="iconfont lebo-gwcpsbxj"></i>
                    </el-tooltip>
                    <div :class="'circle'" v-if="item.high_camera_name"></div>
                  </div>
                  <div :class="[
                    'geomagnetic',
                    item.machine_name
                      ? item.machine_abnormal
                        ? 'geomagnetic_err'
                        : 'geomagnetic_success'
                      : 'geomagnetic_info',
                  ]">
                    <el-tooltip placement="top" effect="light" class="machine_name_font"
                      :style="item.machine_name ? '' : 'visibility:hidden;'">
                      <div slot="content">
                        设备：{{ item.machine_name }}<br />状态：<span :style="item.machine_abnormal ? 'color:red' : 'color:green'
                          ">
                          {{ item.machine_abnormal ? "异常" : "正常" }}</span>
                      </div>
                      <i class="iconfont lebo-cpsblyj"></i>
                    </el-tooltip>
                    <div :class="'circle'" v-if="item.machine_name"></div>
                  </div>
                </div>
              </li>
            </ul>
            <!-- 页码 -->
            <el-pagination @size-change="querySpaceDetailSizeChange" @current-change="querySpaceDetailCurrentChange"
              :current-page="querySpaceDetail.PageIndex" :page-sizes="[10, 20, 30, 40]"
              :page-size="querySpaceDetail.PageSize" layout="total, sizes, prev, pager, next, jumper"
              :total="spaceDetailTotal">
            </el-pagination>
          </el-tab-pane>
        </template>
        <el-tab-pane label="计费方案" name="计费方案">
          <!-- 表格 -->
          <el-table :data="parkingCostData" align="center" header-align="center" tooltip-effect="dark" style="width: 100%"
            border stripe key="parkingCostData">
            <!-- <el-table-column prop="card_name" label="卡类型" width="120"></el-table-column> -->
            <el-table-column prop="scheme_name" label="计费方案名称">
              <template slot-scope="scope">
                <div v-if="scope.row.scheme_name">
                  {{ scope.row.scheme_name }}
                </div>
                <div v-else style="color:#aaa;">未配置</div>
              </template>
            </el-table-column>
            <el-table-column label="方案类型">
              <template slot-scope="scope">
                <div v-if="scope.row.scheme_str">{{ scope.row.scheme_str }}{{ scope.row.is_default ?
                  '【默认方案】' :
                  '' }}</div>
                <div v-else style="color:#aaa;">未配置</div>
              </template>
            </el-table-column>
            <el-table-column label="方案详情">
              <template slot-scope="scope">
                <div v-if="scope.row.scheme_details" v-html="scope.row.scheme_details.replace(/\r\n|\n|\r/g, '<br/>')">
                </div>
                <div v-else style="color:#aaa;">未配置</div>
              </template>
            </el-table-column>
          </el-table>
          <!-- 页码 -->
          <el-pagination @size-change="queryCostDetailSizeChange" @current-change="queryCostDetailCurrentChange" ref="multipleTable"
            :current-page="queryCostDetail.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryCostDetail.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="costDetailTotal">
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="优惠劵" name="优惠劵">
          <template v-if="couponTable">
            <el-table :data="parkingCouponPackageList" :span-method="objectSpanMethod" align="center"
              header-align="center" tooltip-effect="dark" style="width: 100%" border stripe :key="Math.random()"
              ref="myTable">
              <el-table-column prop="coupon_package_name" label="优惠劵套餐" key="coupon_package_name"></el-table-column>
              <el-table-column prop="coupon_package_price" label="售价" key="coupon_package_price">
                <template slot-scope="scope">￥ {{ scope.row.coupon_package_price.toFixed(2) }}</template>
              </el-table-column>
              <el-table-column prop="coupon_name" label="优惠劵名称" key="coupon_name"></el-table-column>
              <el-table-column prop="coupon_type" label="类型" key="coupon_type">
                <template slot-scope="scope">{{ getWayName('CouponType', scope.row.coupon_type)
                }}</template>
              </el-table-column>
              <el-table-column prop="coupon_price" label="单价" key="coupon_price">
                <template slot-scope="scope">￥ {{ scope.row.coupon_price.toFixed(2) }}</template>
              </el-table-column>
              <el-table-column prop="coupon_count" label="张数" key="coupon_count"></el-table-column>
            </el-table>
          </template>
        </el-tab-pane>
        <template v-if="inOutPark === 'outPark'">
          <!-- 路外 -->
          <el-tab-pane label="通行策略" name="通行策略">
            <div class="info_box">
              <el-tree :data="outParkingTreeList" :props="defaultProps" default-expand-all :expand-on-click-node="false"
                @node-click="strategyNodeClick">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span class="custom-tree-node-font"
                    :style="data.type == 2 ? ((bindId ? data.id == bindId : data.id == parkingId) ? 'font-weight: 700;color: #000;color:#409EFF;' : 'font-weight: 700;color: #000;') : ((bindId ? data.id == bindId : data.id == parkingId) ? 'color:#409EFF;' : '')">{{
                      node.label }}</span>
                </span>
              </el-tree>
              <el-divider direction="vertical"></el-divider>
              <el-form label-position="right" label-width="120px" :data="trafficRuleDetail" class="detail_form"
                v-if="type == 2">
                <el-form-item label="车位数："
                  v-if="trafficRuleDetail.parkinglot_type && trafficRuleDetail.parkinglot_type.length > 0">
                  <span style="margin-right:40px;" v-for="(item, index) in trafficRuleDetail.parkinglot_type"
                    :key="index">
                    <el-checkbox disabled :value="true"></el-checkbox>{{ setParkinglotType(item) }}
                  </span>
                </el-form-item>
                <el-form-item label="计费规则：">停车费支付成功后，
                  <span class="detail_span">{{ trafficRuleDetail.minute }}</span>分钟内出声不额外计费
                </el-form-item>
                <el-form-item label="通行规则："
                  v-if="trafficRuleDetail.issecond || trafficRuleDetail.isdiscern || trafficRuleDetail.autoRelease">
                  <div v-if="trafficRuleDetail.issecond">
                    同进同出 <span class="detail_span">{{ trafficRuleDetail.second }}</span>秒内，不扫尾牌
                  </div>
                  <div v-if="trafficRuleDetail.isdiscern">
                    同车道车牌识别差
                    <span class="detail_span">{{ trafficRuleDetail.carsnub }}</span>位，
                    <span class="detail_span">{{ trafficRuleDetail.carssecond }}</span>秒内，不重复识别车牌
                  </div>
                  <div v-if="trafficRuleDetail.autoRelease">
                    无入场记录车辆自动放行
                  </div>
                </el-form-item>
              </el-form>
              <el-form label-position="right" label-width="120px" :data="trafficRuleDetail" class="detail_form"
                v-if="type == 3 && trafficRuleDetail">
                <div v-if="trafficRuleDetail.temporarycars">
                  <span class="title_box" style="margin-top: 0px;">临停车</span>
                  <el-divider></el-divider>
                  <el-form-item label="通行规则：">
                    <span style="margin-right:40px;" v-if="trafficRuleDetail.temporarycars.NoEntry">
                      <el-checkbox disabled :value="true"></el-checkbox>禁止通行
                    </span>
                    <span style="margin-right:40px;" v-if="trafficRuleDetail.temporarycars.NoParkinglotEntry">
                      <el-checkbox disabled :value="true"></el-checkbox>车位满时禁止通行
                    </span>
                    <span style="margin-right:40px;"
                      v-if="!trafficRuleDetail.temporarycars.NoParkinglotEntry && !trafficRuleDetail.temporarycars.NoEntry">
                      允许通行
                    </span>
                  </el-form-item>
                  <el-form-item label="通行时段：">在
                    <span class="detail_span">{{
                      $moment(trafficRuleDetail.temporarycars.Begintime).format("HH:mm:ss") }}</span>至
                    <span class="detail_span">{{
                      $moment(trafficRuleDetail.temporarycars.Endtime).format("HH:mm:ss")
                    }}</span>期间可通行
                  </el-form-item>
                </div>
                <div v-if="trafficRuleDetail.timingcard">
                  <span class="title_box">计时卡</span>
                  <el-divider></el-divider>
                  <el-form-item label="通行规则：">
                    <span style="margin-right:40px;" v-if="trafficRuleDetail.timingcard.NoEntry">
                      <el-checkbox disabled :value="true"></el-checkbox>禁止通行
                    </span>
                    <span style="margin-right:40px;" v-if="trafficRuleDetail.timingcard.NoParkinglotEntry">
                      <el-checkbox disabled :value="true"></el-checkbox>车位满时禁止通行
                    </span>
                    <span style="margin-right:40px;"
                      v-if="!trafficRuleDetail.timingcard.NoParkinglotEntry && !trafficRuleDetail.timingcard.NoEntry">
                      允许通行
                    </span>
                  </el-form-item>
                </div>
                <div v-if="trafficRuleDetail.monthlycard">
                  <span class="title_box">月租卡</span>
                  <el-divider></el-divider>
                  <el-form-item label="通行规则：">
                    <span style="margin-right:40px;" v-if="trafficRuleDetail.monthlycard.NoEntry">
                      <el-checkbox disabled :value="true"></el-checkbox>禁止通行
                    </span>
                    <span style="margin-right:40px;" v-if="trafficRuleDetail.monthlycard.NoParkinglotEntry">
                      <el-checkbox disabled :value="true"></el-checkbox>车位满时禁止通行
                    </span>
                    <span style="margin-right:40px;"
                      v-if="!trafficRuleDetail.monthlycard.NoParkinglotEntry && !trafficRuleDetail.monthlycard.NoEntry">
                      允许通行
                    </span>
                  </el-form-item>
                  <el-form-item label="过期提醒：">剩余
                    <span class="detail_span">{{ trafficRuleDetail.monthlycard.ExpirationMsg
                    }}</span>天提醒
                  </el-form-item>
                </div>
                <div v-if="trafficRuleDetail.storedcard">
                  <span class="title_box">储值卡</span>
                  <el-divider></el-divider>
                  <el-form-item label="通行规则：">
                    <span style="margin-right:40px;" v-if="trafficRuleDetail.storedcard.NoEntry">
                      <el-checkbox disabled :value="true"></el-checkbox>禁止通行
                    </span>
                    <span style="margin-right:40px;" v-if="trafficRuleDetail.storedcard.NoParkinglotEntry">
                      <el-checkbox disabled :value="true"></el-checkbox>车位满时禁止通行
                    </span>
                    <span style="margin-right:40px;"
                      v-if="!trafficRuleDetail.storedcard.NoParkinglotEntry && !trafficRuleDetail.storedcard.NoEntry">
                      允许通行
                    </span>
                  </el-form-item>
                  <el-form-item label="续费提醒：">余额少于是
                    <span class="detail_span">{{ trafficRuleDetail.storedcard.ExpirationMsg }}</span>元提醒
                  </el-form-item>
                </div>
                <div v-if="trafficRuleDetail.vipcard">
                  <span class="title_box">贵宾卡</span>
                  <el-divider></el-divider>
                  <el-form-item label="通行规则：">
                    <span style="margin-right:40px;" v-if="trafficRuleDetail.vipcard.NoEntry">
                      <el-checkbox disabled :value="true"></el-checkbox>禁止通行
                    </span>
                    <span style="margin-right:40px;" v-if="trafficRuleDetail.vipcard.NoParkinglotEntry">
                      <el-checkbox disabled :value="true"></el-checkbox>车位满时禁止通行
                    </span>
                    <span style="margin-right:40px;"
                      v-if="!trafficRuleDetail.vipcard.NoParkinglotEntry && !trafficRuleDetail.vipcard.NoEntry">
                      允许通行
                    </span>
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </el-tab-pane>
        </template>
      </el-tabs>
    </div>
    <!-- 二维码 -->
    <lebo-dialog append-to-body width="50%" title="通道码" :isShow="isShowCode" @close="isShowCode = false" footerSlot closeOnClickModal>
      <div class="content_box">
        <!-- 入口无牌车码 -->
        <div v-if="isInAtress">
          <div class="qr_content">
            <div class="qrTitle">无牌车通道码</div>
            <vue-qr ref="qrRef" id="qrcode" :text="qrCodeUrl" :margin="10"></vue-qr>
            <el-button size="small" class="btn" @click="copyClick(1)">
              <i class="iconfont lebo-fuzhi"></i>
              复制地址
            </el-button>
            <el-button size="small" @click="downQr" class="dowload_btn" style="marginLeft:0;">
              <i class="iconfont lebo-xiazai"></i>
              下载通道二维码
            </el-button>
          </div>
        </div>
        <!-- 出口通行码 -->
        <div class="outQrBox" v-else>
          <div class="qr_content">
            <div class="qrTitle">停车缴费码</div>
            <vue-qr ref="qrRefPlate" id="qrcodePlate" :text="qrCodeUrlPlate" :margin="10"></vue-qr>
            <el-button size="small" class="btn" @click="copyClick(2)">
              <i class="iconfont lebo-fuzhi"></i>
              复制地址
            </el-button>
            <el-button size="small" @click="downQrPlate" class="dowload_btn" style="marginLeft:0;">
              <i class="iconfont lebo-xiazai"></i>
              下载通道二维码
            </el-button>
          </div>
          <div class="qr_content">
            <div class="qrTitle">停车缴费码【无牌车】</div>
            <vue-qr ref="qrRef" id="qrcode" :text="qrCodeUrlOut" :margin="10"></vue-qr>
            <el-button size="small" class="btn" @click="copyClick(1)">
              <i class="iconfont lebo-fuzhi"></i>
              复制地址
            </el-button>
            <el-button size="small" @click="downQr" class="dowload_btn" style="marginLeft:0;">
              <i class="iconfont lebo-xiazai"></i>
              下载通道二维码
            </el-button>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </lebo-dialog>
  </div>
</template>

<script>
import { getOutParkingDetails, getParkingCostConfigList, getOutParkingTreeList, getOutParkingDeviceConfigList, getParkingTrafficRuleDetail } from '@/api/outParking'
import { getParkingDetails, getParkingSpaceDetailList, getParkingCostList } from '@/api/curbParkapi'
import { getParkingCouponPackageList } from '@/api/discountCoupon'
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import { mapMutations, mapGetters, mapState } from 'vuex'
export default {
  props: ['parkingId', 'inOutPark'],
  data () {
    return {
      // parkingId: null,
      // 当前展开的tab
      activeName: '基本信息',
      // 基本信息
      DetailsList: {},
      center: {
        lat: 28.21347823,
        lng: 112.97935279
      },
      jgNameDialog: false,
      show: true,
      postionMap: {
        lat: 28.21347823,
        lng: 112.97935279
      },
      // 计费方案查询
      queryCostDetail: {
        PageSize: 10,
        PageIndex: 1
      },
      // 车位信息查询
      querySpaceDetail: {
        PageSize: 30,
        PageIndex: 1
      },
      find_space_number: '',
      costDetailTotal: 0,
      spaceDetailTotal: 0,
      // 车位信息
      parkingSpaceData: [],
      // 计费方案
      parkingCostData: [],
      map: {},
      // 停车场树结构
      outParkingTreeList: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      // 设备列表
      parkingDevidceConfigList: [],
      // 当前展示的节点
      bindId: '',
      type: 2, // 2表示停车场，3表示出入口
      // 通行策略详情
      trafficRuleDetail: {},
      // 优惠劵
      parkingCouponPackageList: [],
      spanArr: [], // 用于存放需要合并的行的个数
      spanArrOutParking: [], // 通道信息合并行个数
      spanIndex: 0, // 记录spanArr数组的下标
      couponTable: false, // 优惠券列表
      showOutParking: false, // 通道信息
      isShowCode: false, // 是否展示二维码
      qrCodeUrl: '', // 二维码地址入口
      qrCodeUrlOut: '', // 二维码地址出口
      qrCodeUrlPlate: '', // 有牌照二维码地址
      isInAtress: true // 是否是入口
    }
  },
  created () {
    // this.parkingId = this.$route.query.parking_id
    console.log('groupInfo', this.groupInfo)
    setTimeout(() => {
      this.getParkingInfo()
    }, 200)
  },
  mounted () {
  },
  computed: {
    ...mapState('menuList', ['isRoutW', 'groupInfo']),
    ...mapGetters(['getUserInfo', 'getOptions', 'getWayName'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 获取停车场基本信息
    async getParkingInfo () {
      let res = ''
      if (this.inOutPark === 'curPark') {
        // 获取路内车场信息
        res = await getParkingDetails({ parking_id: this.parkingId })
      } else if (this.inOutPark === 'outPark') {
        // 获取路外车场信息
        res = await getOutParkingDetails({ parking_id: this.parkingId })
      }
      // console.log(ret.Data);
      this.DetailsList = res.Data
      this.center = {
        lng: res.Data.longitude,
        lat: res.Data.latitude
      }
      this.postionMap = {
        lng: res.Data.longitude,
        lat: res.Data.latitude
      }
      this.initMap()
    },
    initMap () {
      const that = this
      lazyAMapApiLoaderInstance.load().then(() => {
        this.map = new AMap.Map('amapall', {
          center: new AMap.LngLat(this.center.lng, this.center.lat),
          zoom: 14
        })
        this.map.clearMap()
        var marker = new AMap.Marker({
          map: this.map,
          position: [this.postionMap.lng, this.postionMap.lat]
        })
        var divInfo = document.createElement('div')
        divInfo.className = 'divInfo'
        divInfo.innerHTML = '地址：' + that.DetailsList.position
        var infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -30),
          content: divInfo // 使用默认信息窗体框样式，显示信息内容
        })
        // 鼠标点击marker弹出自定义的信息窗体
        AMap.event.addListener(marker, 'click', function () {
          infoWindow.open(that.map, marker.getPosition())
        })
        infoWindow.open(that.map, marker.getPosition())
      })
    },
    // 获取路内/路外停车场计费方案
    async fnGetParkingCostList () {
      let res = ''
      if (this.inOutPark === 'curPark') {
        // 路内计费方案
        res = await getParkingCostList({
          parking_id: this.parkingId,
          PageSize: this.queryCostDetail.PageSize,
          PageIndex: this.queryCostDetail.PageIndex
        })
      } else if (this.inOutPark === 'outPark') {
        // 路外计费方案
        res = await getParkingCostConfigList({
          parking_id: this.parkingId,
          PageSize: this.queryCostDetail.PageSize,
          PageIndex: this.queryCostDetail.PageIndex
        })
      }
      // console.log(res.Data);
      if (res && res.Code === 200) {
        if (res.Data.DataList && res.Data.DataList.length) {
          this.costDetailTotal = res.Data.TotalCount
          this.parkingCostData = res.Data.DataList
        } else {
          this.costDetailTotal = 0
          this.parkingCostData = []
        }
      } else {
        this.costDetailTotal = 0
        this.parkingCostData = []
      }
    },
    // 获取路内停车场车位信息
    async fnGetParkingSpaceDetailList () {
      const ret = await getParkingSpaceDetailList({
        parking_id: this.parkingId,
        PageSize: this.querySpaceDetail.PageSize,
        PageIndex: this.querySpaceDetail.PageIndex
      })
      // console.log(ret.Data.DataList);
      if (ret.Code === 200) {
        this.spaceDetailTotal = ret.Data.TotalCount
        this.parkingSpaceData = ret.Data.DataList
      } else {
        this.spaceDetailTotal = 0
        this.parkingSpaceData = []
      }
    },
    // 查询路内车位信息
    async onSubmit () {
      const ret = await getParkingSpaceDetailList({
        space_number: this.find_space_number,
        parking_id: this.parkingId,
        PageSize: this.querySpaceDetail.PageSize,
        PageIndex: this.querySpaceDetail.PageIndex
      })
      if (ret.Code === 200) {
        this.spaceDetailTotal = ret.Data.TotalCount
        this.parkingSpaceData = ret.Data.DataList
      } else {
        this.spaceDetailTotal = 0
        this.parkingSpaceData = []
      }
    },
    // 车位信息分页数据变换
    querySpaceDetailCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.querySpaceDetail.PageIndex = val
      this.fnGetParkingSpaceDetailList()
    },
    // 车位信息分页数据变换
    querySpaceDetailSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.querySpaceDetail.PageSize = val
      this.fnGetParkingSpaceDetailList()
    },
    // 计费方案分页数据变换
    queryCostDetailSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryCostDetail.PageSize = val
      this.fnGetParkingCostList()
    },
    // 计费方案分页数据变换
    queryCostDetailCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryCostDetail.PageIndex = val
      this.fnGetParkingCostList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // 点击切换
    handleClick (tab, event) {
      // console.log(tab.label);
      this.bindId = ''
      this.couponTable = false
      this.spanArr = []
      this.spanArrOutParking = []
      this.spanIndex = 0
      if (tab.label === '基本信息') {
        this.getParkingInfo()
      } else if (tab.label === '计费方案') {
        this.fnGetParkingCostList()
      } else if (tab.label === '通道信息') {
        this.fnGetOutParkingTreeList()
        this.fnGetOutParkingDeviceConfigList(this.parkingId)
      } else if (tab.label === '通行策略') {
        this.fnGetOutParkingTreeList()
        this.fnGetParkingTrafficRuleDetail(this.parkingId)
      } else if (tab.label === '优惠劵') {
        this.fngetParkingCouponPackageList()
      } else if (tab.label === '车位信息') {
        this.fnGetParkingSpaceDetailList()
      }
    },
    // 获取路内/路外优惠券方案
    async fngetParkingCouponPackageList () {
      const res = await getParkingCouponPackageList({ parking_id: this.parkingId })
      // console.log(res);
      if (res && res.Code === 200) {
        var arr = []
        res.Data.forEach(item => {
          item.coupons.forEach(data => {
            arr.push({
              coupon_count: data.coupon_count,
              coupon_id: data.coupon_id,
              coupon_name: data.coupon_name,
              coupon_price: data.coupon_price,
              coupon_type: data.coupon_type,
              coupon_valid_day: data.valid_day,
              coupon_valid_time: data.valid_time,
              ...item
            })
          })
        })
        this.parkingCouponPackageList = arr
        this.getSpanArr(arr)
        this.couponTable = true
      } else {
        this.parkingCouponPackageList = []
      }
    },
    // 处理数据 ID
    getSpanArrId (data) {
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArrOutParking.push(1)
          this.spanIndex = 0
        } else {
          // 判断当前行与前一行内容是否相同
          if (data[i].id === data[i - 1].id) {
            this.spanArrOutParking[this.spanIndex] += 1 // 相同的话，当前下标所代表的值加一，例如：第一列的前三行可合并
            this.spanArrOutParking.push(0)// 记录完毕后，再往数组里添加一个元素0，作为下一次合并的初始值
          } else {
            this.spanArrOutParking.push(1) // 否则，依旧是一行
            this.spanIndex = i
          }
        }
      }
    },
    // 处理数据
    getSpanArr (data) {
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          this.spanIndex = 0
        } else {
          // 判断当前行与前一行内容是否相同
          if (data[i]._id === data[i - 1]._id) {
            this.spanArr[this.spanIndex] += 1 // 相同的话，当前下标所代表的值加一，例如：第一列的前三行可合并
            this.spanArr.push(0)// 记录完毕后，再往数组里添加一个元素0，作为下一次合并的初始值
          } else {
            this.spanArr.push(1) // 否则，依旧是一行
            this.spanIndex = i
          }
        }
      }
    },
    // 合并多行单元格的方法
    /**
                         * row:表格行
                         * column:表格列
                         * rowIndex：表格行下标
                         * columnIndex：表格列下标
                         * */
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      // 需要合并的列下标
      if (columnIndex === 0 || columnIndex === 1) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        // console.log(`rowspan:${_row} colspan:${_col}`);
        return {
          // [0,0] 表示这一行不显示， [2,1]表示行的合并数
          rowspan: _row,
          colspan: _col
        }
      }
    },
    // 合并方法2
    objectSpanMethodId ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 5) {
        const _row = this.spanArrOutParking[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    // 获取路外停车场树结构
    async fnGetOutParkingTreeList () {
      const res = await getOutParkingTreeList({
        parkid: this.parkingId
      })
      // console.log('获取停车场树结构------', res)
      if (res && res.Code === 200 && res.Data) {
        this.outParkingTreeList = res.Data
      } else {
        this.outParkingTreeList = []
      }
    },
    // 获取设备配置列表
    async fnGetOutParkingDeviceConfigList (id) {
      const res = await getOutParkingDeviceConfigList({
        prakingid: id
      })
      console.log('获取设备配置列表', res)
      if (res && res.Code === 200 && res.Data) {
        res.Data.forEach(item => {
          if (item.cameratype === 2) {
            item.cameratype = '出口'
          } else if (item.cameratype === 1) {
            item.cameratype = '入口'
          }
        })
        this.parkingDevidceConfigList = res.Data
        this.getSpanArrId(res.Data)
        this.showOutParking = true
        // this.getSpanArr(arr)
      } else {
        this.parkingDevidceConfigList = []
      }
    },
    // 点击设备配置中的树控件
    deviceNodeClick (data, node, key) {
      // console.log('deviceNodeClick----data----', data);
      // console.log('deviceNodeClick----node----', node);
      // console.log('deviceNodeClick----key----', key);
      this.spanArrOutParking = []
      this.showOutParking = false
      this.bindId = data.id
      this.fnGetOutParkingDeviceConfigList(data.id)
    },
    // 获取通行策略
    async fnGetParkingTrafficRuleDetail (id) {
      const res = await getParkingTrafficRuleDetail({
        configid: id
      })
      // console.log(res);
      if (res.Code === 200 && res.Data) {
        if (this.type === 2) {
          this.trafficRuleDetail = res.Data.parkingrules.parkTrafficRule
          this.trafficRuleDetail.minute = res.Data.parkingrules.minute
          // 1.计时卡不计  2.月租卡不计 3.储值卡不计 4.贵宾卡不计
          this.trafficRuleDetail.parkinglot_type = res.Data.parkingrules.parkinglot_type
        } else if (this.type === 3) {
          this.trafficRuleDetail = res.Data.gaterules
        }
      }
    },
    setParkinglotType (val) {
      switch (Number(val)) {
        case 1:
          return '计时卡不计'
        case 2:
          return '月租卡不计'
        case 3:
          return '储值卡不计'
        case 4:
          return '贵宾卡不计'
      }
    },
    // 点击路外通行策略中的树控件
    strategyNodeClick (data, node, key) {
      // console.log('strategyNodeClick----data----', data);
      // console.log('strategyNodeClick----node----', node);
      // console.log('strategyNodeClick----key----', key);
      this.bindId = data.id
      this.type = data.type
      if (data.type === 2) {
        this.fnGetParkingTrafficRuleDetail(this.parkingId)
      } else {
        this.fnGetParkingTrafficRuleDetail(data.id)
      }
    },
    // 下载二维码
    downQr () {
      var name = new Date().getTime()
      var a = document.createElement('a')
      console.log(this.$refs.qrRef.$el.src)
      a.style.display = 'none'
      a.download = name
      a.href = this.$refs.qrRef.$el.src
      document.body.appendChild(a)
      a.click()
    },
    downQrPlate () {
      var name = new Date().getTime()
      var a = document.createElement('a')
      console.log(this.$refs.qrRefPlate.$el.src)
      a.style.display = 'none'
      a.download = name
      a.href = this.$refs.qrRefPlate.$el.src
      document.body.appendChild(a)
      a.click()
    },
    // 查看二维码
    lookOutParkCode (row) {
      if (row.cameratype === '入口') {
        this.isInAtress = true
      } else {
        this.isInAtress = false
      }

      console.log('row', row)
      const windowsProtocol = window.location.protocol
      const originUrl = this.groupInfo.defaultDomain.split('//')[1]
      const windowsHost = originUrl

      // const windowsHost = window.location.host
      console.log('windowsHref', windowsProtocol, windowsHost)
      // 无牌
      const codeUrl = `${windowsProtocol + '//' + windowsHost}/payPages/get-scan-payment?sn=${row.devicesn}&id=&tp=1&group_id=${row.group_id}`
      this.qrCodeUrl = codeUrl

      const codeUrlout = `${windowsProtocol + '//' + windowsHost}/payPages/get-scan-payment?sn=${row.devicesn}&id=&tp=2&group_id=${row.group_id}`
      this.qrCodeUrlOut = codeUrlout
      // 有牌
      const codeUrlPlate = `${windowsProtocol + '//' + windowsHost}/payPages/channel-code-payment?sn=${row.devicesn}&id=&tp=1&group_id=${row.group_id}&access_id=${row.PrakingID}`
      this.qrCodeUrlPlate = codeUrlPlate
      this.isShowCode = true
    },
    copyClick (type) {
      const input = document.createElement('input') // 创建input对象
      if (type === 1) {
        // 无牌车
        if (this.isInAtress) {
          input.value = this.qrCodeUrl// 设置复制内容入口
        } else {
          input.value = this.qrCodeUrlOut// 设置复制内容出口
        }
      } else {
        input.value = this.qrCodeUrlPlate// 设置复制内容
      }
      document.body.appendChild(input) // 添加临时实例
      input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      console.log(input)
      if (document.execCommand('Copy', 'false', null)) {
        // 如果复制成功
        // console.log('点击内容已复制');
        this.$message.success('复制成功')
      }
      // 复制成功之后删除增加的这个input元素
      document.body.removeChild(input)
    }
  }
}
</script>

<style scoped lang="less">
.detail_form {
  text-align: left;
  width: 100%;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;

  /deep/ .el-form-item {
    margin-bottom: 0;
  }

  .title_box {
    display: inline-block;
    font-size: 17px;
    font-weight: 700;
    color: #000;
    margin-top: 30px;
  }

  .detail_span {
    font-weight: 700;
    color: #000;
    font-size: 15px;
    display: inline-block;
    margin: 0 10px;
  }
}

.all-amap {
  width: 100%;
  height: 480px;
  margin-left: -50px;

  /deep/.amap-maps {
    .amap-drags {
      .amap-overlays {
        .amap-info {
          .amap-info-contentContainer {
            .amap-info-content {
              background: blanchedalmond;

              .divInfo {
                line-height: 25px;
              }
            }

            .amap-info-sharp {
              bottom: 0;
              left: 50%;
              margin-left: -8px;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-top: 8px solid blanchedalmond;
            }

            .amap-info-close {
              position: absolute;
              top: 5px;
              right: 5px !important;
            }
          }
        }
      }
    }
  }
}

/* 图标列表 */
.ul_box {
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  height: 520px;
  // max-height: 550px;
  overflow-y: auto;

  .device_li {
    float: left;
    width: 145px;
    height: 160px;
    border-radius: 5px;
    background-color: #fff;
    border-radius: 2px #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3);
    padding: 0 10px;
    box-sizing: border-box;

    // .el-badge.el-tooltip {
    //   width: 100%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }
    .title {
      width: 100%;
      font-size: 18px;
      color: #666;
      height: 35px;
      line-height: 35px;
      font-family: MicrosoftYaHei-Bold;
      font-weight: normal;
      font-stretch: normal;
    }

    .s0 {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      .geomagnetic {
        width: 56px;
        height: 51px;
        float: left;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 10px;

        .iconfont {
          font-size: 26px;
          margin-right: 5px;
        }

        .circle {
          width: 8px;
          height: 8px;
          // background-color: #1acd90;
          border-radius: 50%;
          position: absolute;
          right: 5px;
          bottom: 5px;
        }
      }

      .geomagnetic_info {
        background: #fafafa;
      }

      .geomagnetic_success {
        cursor: pointer;
        background: #e8faf4;

        .circle {
          background-color: #1acd90;
        }

        &:hover {
          background: #1acd90;

          .circle {
            background-color: #fff;
          }

          .iconfont {
            color: #fff;
          }
        }
      }

      .geomagnetic_err {
        cursor: pointer;
        background: #feedef;

        .circle {
          background-color: #f55265;
        }

        &:hover {
          background: #f55265;

          .circle {
            background-color: #fff;
          }

          .iconfont {
            color: #fff;
          }
        }
      }
    }
  }
}

.el-tabs {
  /deep/ .el-tabs__content {
    overflow: inherit !important;
  }

  /deep/ .el-tabs__item {
    font-size: 18px !important;
    font-weight: 700 !important;
  }

  /deep/ .el-tabs__nav-wrap::after {
    height: 1.5px;
    background-color: #f2f2f2;
  }

  /deep/ .el-tabs__active-bar {
    height: 1.5px;
  }

  /deep/ .el-tabs__item {
    border-right: none !important;
  }
}

.el-tab-pane {
  display: flex;
  flex-direction: column;
}

.info_box {
  display: flex;

  .el-tree {
    width: 20%;
  }

  .el-table {
    width: 80%;
  }

  .el-divider--vertical {
    margin: 0 30px;
    height: inherit;
  }
}

.el-checkbox {
  margin-right: 10px;
}

.custom-tree-node-font {
  font-size: 15px;
}

.video_pile_name_font {
  .iconfont {
    font-size: 33px;
    margin-right: 3px;
  }
}

.high_camera_name_font {
  .iconfont {
    font-size: 24px;
    margin-right: 5px;
  }
}

.machine_name_font {
  .iconfont {
    font-size: 16px;
    margin-right: 1px;
  }
}

.content_box {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .outQrBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .qr_content {
    // width: 194px;
    //   height: 258px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    #qrcodePlate,
    #qrcode {
      width: 90%;
    }

    .qrTitle {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }

    .btn {
      width: 90%;
      height: 30px;
      background: rgba(2, 166, 251, 0.1);
      border-radius: 5px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      color: #01a6fb;
      cursor: pointer;
      padding: unset;
      margin-top: 10px;

      .iconfont {
        color: #01a6fb !important;
      }
    }

    .dowload_btn {
      padding: unset;
      width: 90%;
      margin-top: 10px;
      height: 30px;
      background: rgba(245, 82, 101, 0.1);
      border-radius: 5px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      color: #f55265;
      cursor: pointer;

      .iconfont {
        color: #f55265 !important;
      }
    }
  }

}

/deep/ .el-dialog {
  width: fit-content !important;
}</style>
