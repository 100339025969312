<template>
  <!-- 路内停车场 ————> 设备配置 -->
  <div class="box-card" v-loading="loading" element-loading-text="配置中...." element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <!-- 主要内容 -->
    <div class="content_box">
      <!-- 设备列表 -->
      <div id="left_box">
        <div class="rowTitle deciveList">设备列表</div>
        <el-menu class="my_menu" text-align="left" :collapse-transition="false" :default-active="defaultActive"
          unique-opened @open="handleOpen" @close="handleClose">
          <el-submenu index="highCamera">
            <template slot="title">
              <div>
                <i :class="[
                  'iconfont',
                  defaultSubActive == 'highCamera'
                    ? 'iconcaret-bottom'
                    : 'iconcaret-right',
                ]"></i>
                <span class="text_span">高位车牌识别相机</span>
              </div>
              <span class="text_span1">{{ highCameraTotal }}</span>
            </template>
            <el-menu-item-group v-infinite-scroll="highCameraLoad" :infinite-scroll-immediate="false"
              style="max-height: 500px; overflow-y: scroll">
              <template v-if="highCameraList.length > 0">
                <el-menu-item v-for="(item, index) in highCameraList" :key="index" :index="item.device_serial" @click="
                  clickHighCamera(item);
                activeIndex = index;
                ">
                  <span>{{ item.device_name }}</span>
                  <span>{{ item.device_count }}</span>
                </el-menu-item>
              </template>
              <el-menu-item v-else index="highCamera">暂无设备</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="video">
            <template slot="title">
              <div>
                <i :class="[
                  'iconfont',
                  defaultSubActive == 'video'
                    ? 'iconcaret-bottom'
                    : 'iconcaret-right',
                ]"></i>
                <span class="text_span">车牌识别视频桩</span>
              </div>
              <span class="text_span1"> {{ videoPileTotal }}</span>
            </template>
            <el-menu-item-group v-infinite-scroll="videoPileLoad" :infinite-scroll-immediate="false"
              style="max-height: 500px; overflow-y: scroll">
              <template v-if="videoPileList.length > 0">
                <el-menu-item v-for="(item, index) in videoPileList" :key="index" :index="item.device_serial" @click="
                  clickvideoPile(item);
                activeIndex = index;
                ">
                  <span>{{ item.device_name }}</span>
                  <span>{{ item.device_count }}</span>
                </el-menu-item>
              </template>
              <el-menu-item v-else index="videoPile">暂无设备</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="deviceMachine">
            <template slot="title">
              <div>
                <i :class="[
                  'iconfont',
                  defaultSubActive == 'deviceMachine'
                    ? 'iconcaret-bottom'
                    : 'iconcaret-right',
                ]"></i>
                <span class="text_span">车牌识别路牙机</span>
              </div>
              <span class="text_span1"> {{ deviceMachineTotal }}</span>
            </template>
            <el-menu-item-group v-infinite-scroll="deviceMachineLoad" :infinite-scroll-immediate="false"
              style="max-height: 500px; overflow-y: scroll">
              <template v-if="deviceMachineList.length > 0">
                <el-menu-item v-for="(item, index) in deviceMachineList" :key="index" :index="item.device_serial" @click="
                  clickHighCamera(item);
                activeIndex = index;
                ">
                  <span>{{ item.device_name }}</span>
                  <span>{{ item.device_count }}</span>
                </el-menu-item>
              </template>
              <el-menu-item v-else index="deviceMachine">暂无设备</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="geomagnetic">
            <template slot="title">
              <div>
                <i :class="[
                  'iconfont',
                  defaultSubActive == 'geomagnetic'
                    ? 'iconcaret-bottom'
                    : 'iconcaret-right',
                ]"></i>
                <span class="text_span">嵌入式地磁</span>
              </div>
              <span class="text_span1"> {{ geomagneticTotal }}</span>
            </template>
            <el-menu-item-group v-infinite-scroll="geomagneticLoad" :infinite-scroll-immediate="false"
              style="max-height: 500px; overflow-y: scroll">
              <template v-if="geomagneticList.length > 0">
                <el-menu-item v-for="(item, index) in geomagneticList" :key="index" :index="item.device_serial" @click="
                  clickHighCamera(item);
                activeIndex = index;
                ">
                  <span>{{ item.device_name }}</span>
                  <span>{{ item.device_count }}</span>
                </el-menu-item>
              </template>
              <el-menu-item v-else index="geomagnetic">暂无设备</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </div>
      <div>
        <el-divider class="dividerLeft" direction="vertical" content-position="left"></el-divider>
      </div>
      <!-- 已配置与待配置 -->
      <div class="right_box">
        <!-- <div class="title">已配置【{{parking_name}}】</div> -->
        <div class="rowTitle">已配置</div>
        <el-divider></el-divider>
        <!-- 表格 -->
        <el-table class="config_table" ref="configTable" :data="configList" align="center" row-key="_id"
          header-align="center" tooltip-effect="dark" border stripe>
          <el-table-column align="center" label="序号" type="index" :index="indexMethod" width="50"></el-table-column>
          <el-table-column prop="space_number" label="车位编号"></el-table-column>
          <el-table-column prop="parking_name" label="停车场"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="err" icon="quxiaopeizhi" hint="取消配置" :disabled="isSetDisabled"
                @click="cancelConfig(scope.row)"></lbButton>
              <lbButton type="warning" icon="tuodongpaixu" hint="拖动顺序" :disabled="isSetDisabled"
                @click="setSort(scope.row)" style="cursor: move" v-if="isShow"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="title">未配置</div> -->
        <div class="rowTitle">未配置</div>
        <el-divider></el-divider>
        <!-- 查询表单 -->
        <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
          @submit.native.prevent>
          <el-form-item label="车位编号：">
            <el-input v-model="queryForm.space_number" @keyup.enter.native="onSubmit" placeholder="请输入车位编号"
              @input="(e) => (queryForm.space_number = validSpace(e))">
              <i slot="suffix" class="iconfont lebo-sousuo" :fill="true" @click="onSubmit()"></i>
            </el-input>
          </el-form-item>
          <!-- <el-form-item>
                <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
              </el-form-item> -->
        </el-form>
        <!-- 表格 -->
        <el-table class="noconfig_table" ref="noConfigTable" :data="noConfigList" align="center" header-align="center"
          tooltip-effect="dark" border stripe>
          <el-table-column prop="space_number" label="车位编号"></el-table-column>
          <el-table-column prop="parking_name" label="停车场"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="setting" icon="peizhi2" hint="配置" @click="addConfig(scope.row)" :disabled="isSetDisabled">
              </lbButton>
            </template>
          </el-table-column>
        </el-table>
        <!-- 页码 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="noConfigTotal">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import { mapGetters, mapMutations, mapState } from 'vuex'
import {
  getHighCameraList,
  getVideoPileList,
  getGeomagneticList,
  getlnParkingDeviceNotConfigList,
  getlnParkingDeviceConfigList,
  setlnParkingDevice,
  getDeviceMachineList
} from '@/api/curbParkapi'
export default {
  props: {
    parking_name: { required: true },
    parking_id: { required: true }
  },
  data () {
    return {
      //   parking_id: null,
      defaultActive: '',
      defaultSubActive: '',
      activeIndex: 0,
      // 设备类型
      device_type: 1,
      // 未配置车位列表查询表单
      queryForm: {
        space_number: '',
        PageSize: 10,
        PageIndex: 1
      },
      noConfigTotal: 0,
      // 高位相机
      highCameraList: [],
      highCameraFlag: true,
      highCameraForm: {
        PageSize: 10,
        PageIndex: 1
      },
      highCameraTotal: 0,
      // 视频桩
      videoPileList: [],
      videoPileFlag: true,
      videoPileForm: {
        PageSize: 10,
        PageIndex: 1
      },
      videoPileTotal: 0,
      // 地磁
      geomagneticList: [],
      geomagneticFlag: true,
      geomagneticForm: {
        PageSize: 10,
        PageIndex: 1
      },
      geomagneticTotal: 0,
      // 路牙机
      deviceMachineList: [],
      deviceMachineFlag: true,
      deviceMachineForm: {
        PageSize: 10,
        PageIndex: 1
      },
      deviceMachineTotal: 0,
      // 已配置
      configList: [],
      // 待配置
      noConfigList: [],
      isShow: true,
      //   parking_name: '',
      // 视频桩配置车位数
      deviceVideoCount: 1,
      // 是否禁用配置按钮
      isSetDisabled: false,
      loading: false,
      el: null
    }
  },
  created () {
    // this.parking_id = this.$route.query.parking_id
    // this.parking_name = this.$route.query.parking_name
    this.fnGetHighCameraList()
    this.fnGetVideoPileList()
    this.fnGetGeomagneticList()
    this.fnGetDeviceMachineList()
    this.handleOpen('highCamera') // 展开第一项
  },
  computed: {
    ...mapGetters(['theme']),
    ...mapState('menuList', ['isRoutW'])
  },
  mounted () {
    this.el = this.$refs.configTable.$el.querySelectorAll(
      '.el-table__body-wrapper > table > tbody'
    )[0]
    // console.log('mounted------', this.el);
    this.setSort()
  },
  watch: {
    defaultSubActive (newVal, oldVal) {
      console.log('defaultSubActive--------', newVal)
    }
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // // 获取高位识别相机设备列表
    async fnGetHighCameraList () {
      if (!this.highCameraFlag) return
      this.highCameraFlag = false
      const res = await getHighCameraList({
        parking_id: this.parking_id,
        PageSize: this.highCameraForm.PageSize,
        PageIndex: this.highCameraForm.PageIndex
      })
      // console.log(' 获取高位识别相机设备列表1----', this.highCameraList);
      this.highCameraList = this.deepClone(this.highCameraList).concat(
        res.Data.DataList
      )
      this.highCameraTotal = res.Data.TotalCount
      // console.log(' 获取高位识别相机设备列表2----', this.highCameraList);
      if (this.highCameraTotal === 0) {
        this.defaultActive = 'highCamera'
        this.defaultSubActive = 'highCamera'
      }
      if (res.Data && res.Data.DataList.length > 0) {
        // console.log('--this.defaultActive--', this.defaultActive);
        if (!this.defaultActive) {
          this.defaultActive = this.highCameraList[0].device_serial
        }
        // this.highCameraLoad()
      }
      if (this.highCameraForm.PageIndex === 1) {
        this.highCameraLoad()
      }
      this.highCameraFlag = true
      this.fnGetlnParkingDeviceConfigList()
      this.fnGetlnParkingDeviceNotConfigList()
    },
    // 获取更多高位车牌识别相机
    highCameraLoad () {
      if (this.highCameraList.length < this.highCameraTotal) {
        this.highCameraForm.PageIndex++
        this.highCameraFlag = true
        this.fnGetHighCameraList()
      } else {

      }
    },
    // 获取车牌识别视频桩设备列表
    async fnGetVideoPileList () {
      if (!this.videoPileFlag) return
      this.videoPileFlag = false
      const res = await getVideoPileList({
        parking_id: this.parking_id,
        PageSize: this.videoPileForm.PageSize,
        PageIndex: this.videoPileForm.PageIndex
      })
      // console.log('获取车牌识别视频桩设备列表-----', res.Data);
      this.videoPileList = this.deepClone(this.videoPileList).concat(
        res.Data.DataList
      )
      this.videoPileTotal = res.Data.TotalCount
      if (this.videoPileTotal === 0) {
        this.defaultActive = 'videoPile'
        this.defaultSubActive = 'videoPile'
      }
      if (res.Data && res.Data.DataList.length > 0) {
        if (!this.defaultActive) {
          this.defaultActive = res.Data.DataList[0].device_serial
        }
      }
      if (this.videoPileForm.PageIndex === 1) {
        this.videoPileLoad()
      }
      this.videoPileFlag = true
      this.fnGetlnParkingDeviceConfigList()
      this.fnGetlnParkingDeviceNotConfigList()
    },
    // 获取更多车牌识别视频桩
    videoPileLoad () {
      if (this.videoPileList.length < this.videoPileTotal) {
        this.videoPileForm.PageIndex++
        this.videoPileFlag = true
        this.fnGetVideoPileList()
      } else {

      }
    },
    // 获取地磁设备列表
    async fnGetGeomagneticList () {
      if (!this.geomagneticFlag) return
      this.geomagneticFlag = false
      const res = await getGeomagneticList({
        parking_id: this.parking_id,
        PageSize: this.geomagneticForm.PageSize,
        PageIndex: this.geomagneticForm.PageIndex
      })
      // console.log(res.Data);
      this.geomagneticList = this.geomagneticList.concat(res.Data.DataList)
      this.geomagneticTotal = res.Data.TotalCount
      if (this.geomagneticTotal === 0) {
        this.defaultActive = 'geomagnetic'
        this.defaultSubActive = 'geomagnetic'
      }
      if (res.Data && res.Data.DataList.length > 0) {
        if (!this.defaultActive) {
          this.defaultActive = res.Data.DataList[0].device_serial
        }
      }
      if (this.geomagneticForm.PageIndex === 1) {
        this.geomagneticLoad()
      }
      this.geomagneticFlag = true
      this.fnGetlnParkingDeviceConfigList()
      this.fnGetlnParkingDeviceNotConfigList()
    },
    // 获取更多嵌入式地磁
    geomagneticLoad () {
      if (this.geomagneticList.length < this.geomagneticTotal) {
        this.geomagneticForm.PageIndex++
        this.geomagneticFlag = true
        this.fnGetGeomagneticList()
      } else {

      }
    },
    //  获取路牙机设备列表
    async fnGetDeviceMachineList () {
      if (!this.deviceMachineFlag) return
      this.deviceMachineFlag = false
      const res = await getDeviceMachineList({
        parking_id: this.parking_id,
        PageSize: this.deviceMachineForm.PageSize,
        PageIndex: this.deviceMachineForm.PageIndex
      })
      // console.log('获取路牙机设备列表', res.Data);
      this.deviceMachineList = this.deviceMachineList.concat(res.Data.DataList)
      this.deviceMachineTotal = res.Data.TotalCount
      if (this.deviceMachineTotal === 0) {
        this.defaultActive = 'deviceMachine'
        this.defaultSubActive = 'deviceMachine'
      }
      if (res.Data && res.Data.DataList.length > 0) {
        if (!this.defaultActive) {
          this.defaultActive = res.Data.DataList[0].device_serial
        }
      }
      if (this.deviceMachineForm.PageIndex === 1) {
        this.deviceMachineLoad()
      }
      this.deviceMachineFlag = true
      this.fnGetlnParkingDeviceConfigList()
      this.fnGetlnParkingDeviceNotConfigList()
    },
    deviceMachineLoad () {
      if (this.deviceMachineList.length < this.deviceMachineTotal) {
        this.deviceMachineForm.PageIndex++
        this.deviceMachineFlag = true
        this.fnGetDeviceMachineList()
      } else {

      }
    },
    // 获取停车场设备未配置车位列表
    async fnGetlnParkingDeviceNotConfigList () {
      const res = await getlnParkingDeviceNotConfigList({
        device_type: this.device_type,
        parking_id: this.parking_id,
        space_number: this.queryForm.space_number,
        PageIndex: this.queryForm.PageIndex,
        PageSize: this.queryForm.PageSize
      })
      this.isSetDisabled = false
      this.loading = false
      // console.log(res.Data.DataList);
      this.noConfigList = res.Data.DataList
      this.noConfigTotal = res.Data.TotalCount
      if (res.Data.DataList.length <= 0 && this.queryForm.PageIndex > 1) {
        this.queryForm.PageIndex = this.queryForm.PageIndex - 1
        this.fnGetlnParkingDeviceNotConfigList()
      }
    },
    // 未配置车位列表每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetlnParkingDeviceNotConfigList()
    },
    // 未配置车位列表页码改变
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryForm.PageIndex = val
      this.fnGetlnParkingDeviceNotConfigList()
      this.$refs.noConfigTable.bodyWrapper.scrollTop = 0
    },
    // 未配置车位列表查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetlnParkingDeviceNotConfigList()
    },
    //  获取停车场设备已配置车位列表
    async fnGetlnParkingDeviceConfigList () {
      const res = await getlnParkingDeviceConfigList({
        parking_id: this.parking_id,
        device_type: this.device_type,
        device_serial: this.defaultActive
      })
      this.isSetDisabled = false
      this.loading = false
      if (res.Data) {
        // console.log('获取停车场设备已配置车位列表' + JSON.stringify(res.Data));
        if (res.Data.length > 1) {
          var arr = res.Data
          arr.sort(this.sortId)
          // console.log(arr);
          this.configList = arr
          this.isShow = true
        } else {
          this.configList = res.Data
          this.isShow = false
        }
      }
    },
    sortId (a, b) {
      return a.high_camera_sequence - b.high_camera_sequence
    },
    // 展开指定的 sub-menu
    handleOpen (key, keyPath) {
      this.defaultActive = ''
      this.defaultSubActive = key
      this.highCameraList = []
      this.videoPileList = []
      this.geomagneticList = []
      this.deviceMachineList = []
      // console.log('handleOpen---' + key);
      if (key === 'highCamera') {
        this.isShow = true
        this.device_type = 1
        this.highCameraForm.PageIndex = 1
        // 获取高位相机设备列表
        this.fnGetHighCameraList()
      } else if (key === 'video') {
        this.isShow = false
        this.device_type = 2
        this.videoPileForm.PageIndex = 1
        // 获取车牌识别视频桩设备列表
        this.fnGetVideoPileList()
      } else if (key === 'geomagnetic') {
        this.isShow = false
        this.device_type = 3
        this.geomagneticForm.PageIndex = 1
        //  获取地磁设备列表
        this.fnGetGeomagneticList()
      } else if (key === 'deviceMachine') {
        this.isShow = false
        this.device_type = 4
        this.deviceMachineForm.PageIndex = 1
        //  获取路牙机设备列表
        this.fnGetDeviceMachineList()
      }
    },
    // 收起指定的 sub-menu
    handleClose (key, keyPath) {
      // console.log('handleClose---' + key);
      this.defaultSubActive = ''
      this.highCameraList = []
      this.videoPileList = []
      this.geomagneticList = []
      this.deviceMachineList = []
      if (key === 'video') {
        this.highCameraForm.PageIndex = 0
      } else if (key === 'geomagnetic') {
        this.videoPileForm.PageIndex = 0
      } else if (key === 'deviceMachine') {
        this.geomagneticForm.PageIndex = 0
      } else if (key === 'deviceMachine') {
        this.deviceMachineForm.PageIndex = 0
      }
    },
    // 点击高位相机、视频桩、嵌入式地磁的每一项子类
    clickHighCamera (item) {
      // console.log("clickHighCamera----", item);
      this.defaultActive = item.device_serial
      this.fnGetlnParkingDeviceConfigList()
      this.fnGetlnParkingDeviceNotConfigList()
    },
    clickvideoPile (item) {
      if (item.video_pile_model_id === '60e55f21262aec19e3d1dd19') {
        this.deviceVideoCount = 2
      } else {
        this.deviceVideoCount = 1
      }
      this.defaultActive = item.device_serial
      this.fnGetlnParkingDeviceConfigList()
      this.fnGetlnParkingDeviceNotConfigList()
    },
    // 取消配置
    cancelConfig (row) {
      this.isSetDisabled = true
      this.loading = true
      var obj = {
        parking_id: row.parking_id,
        device_serial: this.defaultActive,
        list_device: [
          {
            sort: 0,
            parking_space_id: row._id
          }
        ],
        device_type: this.device_type,
        operator_type: 2
      }
      // console.log(obj);
      this.fnSetlnParkingDevice(obj)
    },
    // 序号
    indexMethod (index) {
      return index + 1
    },
    // 拖动排序
    setSort (row) {
      // this.isSetDisabled = true
      // this.loading = true
      // console.log(this.configList);
      // const el = this.$refs.configTable.$el.querySelectorAll(
      //   ".el-table__body-wrapper > table > tbody"
      // )[0];
      // console.log(this.el);
      this.sortable = Sortable.create(this.el, {
        // drop placeholder的css类名
        ghostClass: 'sortable-ghost',
        // ************* 拖动对象移动样式
        // dragClass: 'drag',
        // ************* 禁用html5原生拖拽行为
        // forceFallback: true,
        setData: function (dataTransfer) {
          dataTransfer.setData('Text', '')
        },
        onEnd: (evt) => {
          const targetRow = this.configList.splice(evt.oldIndex, 1)[0]
          this.configList.splice(evt.newIndex, 0, targetRow)
          // device_type： 1（高位识别相机）2（视频桩） 3（地磁）
          // operator_type： 1（配置）2（取消配置） 3（拖动排序）
          // console.log(this.configList);
          var arr = []
          for (var i = 0; i < this.configList.length; i++) {
            arr.push({
              parking_space_id: this.configList[i]._id,
              sort: i + 1
            })
            if (arr.length === this.configList.length) {
              var obj = {
                parking_id: this.parking_id,
                device_serial: this.defaultActive,
                list_device: arr,
                device_type: 1,
                operator_type: 3
              }
              // console.log(obj);
              this.fnSetlnParkingDevice(obj)
            }
          }
        }
      })
    },
    // 配置
    addConfig (row) {
      if (this.device_type === 1) {
        if (this.highCameraList.length <= 0) {
          this.$msg.info('暂无设备可配置！')
          return
        }
      } else if (this.device_type === 2) {
        if (this.videoPileList.length <= 0) {
          this.$msg.info('暂无设备可配置！')
          return
        }
      } else if (this.device_type === 3) {
        if (this.geomagneticList.length <= 0) {
          this.$msg.info('暂无设备可配置！')
          return
        }
      } else if (this.device_type === 4) {
        if (this.deviceMachineList.length <= 0) {
          this.$msg.info('暂无设备可配置！')
          return
        }
      }
      this.isSetDisabled = true
      this.loading = true
      // console.log(row);
      var obj = {
        parking_id: row.parking_id,
        device_serial: this.defaultActive,
        list_device: [
          {
            sort: this.configList.length + 1,
            parking_space_id: row._id
          }
        ],
        device_type: this.device_type,
        operator_type: 1
      }
      // console.log(obj);
      if (this.device_type === 3) {
        // 地磁配置数据判断
        if (this.configList.length >= 1) {
          this.isSetDisabled = false
          this.loading = false
          this.$msg.info('该设备已绑定过车位，请先取消绑定！')
        } else {
          this.fnSetlnParkingDevice(obj)
        }
      } else if (this.device_type === 2) {
        // 视频桩配置数据判断
        // console.log("this.deviceVideoCount--", this.deviceVideoCount);
        if (this.configList.length >= this.deviceVideoCount) {
          this.isSetDisabled = false
          this.loading = false
          this.$msg.info('该设备已绑定过车位，请先取消绑定！')
        } else {
          this.fnSetlnParkingDevice(obj)
        }
      } else if (this.device_type === 1) {
        if (this.configList.length >= 10) {
          // 高位相机配置数据判断
          this.isSetDisabled = false
          this.loading = false
          this.$msg.info('该相机已绑定十个车位，请先取消绑定！')
        } else {
          this.fnSetlnParkingDevice(obj)
        }
      } else if (this.device_type === 4) {
        if (this.configList.length >= 1) {
          this.isSetDisabled = false
          this.loading = false
          this.$msg.info('该设备已绑定过车位，请先取消绑定！')
        } else {
          this.fnSetlnParkingDevice(obj)
        }
      }
    },
    // 停车场设备配置请求
    async fnSetlnParkingDevice (obj) {
      // this.loading = true
      const res = await setlnParkingDevice(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.fnGetlnParkingDeviceConfigList()
        this.fnGetlnParkingDeviceNotConfigList()
        if (this.device_type === 1) {
          if (obj.operator_type === 1) {
            this.highCameraList[this.activeIndex].device_count++
          } else if (obj.operator_type === 2) {
            this.highCameraList[this.activeIndex].device_count--
          }
        } else if (this.device_type === 2) {
          if (obj.operator_type === 1) {
            this.videoPileList[this.activeIndex].device_count++
          } else if (obj.operator_type === 2) {
            this.videoPileList[this.activeIndex].device_count--
          }
        } else if (this.device_type === 3) {
          if (obj.operator_type === 1) {
            this.geomagneticList[this.activeIndex].device_count++
          } else if (obj.operator_type === 2) {
            this.geomagneticList[this.activeIndex].device_count--
          }
        } else if (this.device_type === 4) {
          if (obj.operator_type === 1) {
            this.deviceMachineList[this.activeIndex].device_count++
          } else if (obj.operator_type === 2) {
            this.deviceMachineList[this.activeIndex].device_count--
          }
        }
      }
      this.loading = false
      // if (this.device_type === 1) {
      //   // this.highCameraFlag = true;
      //   this.highCameraForm.PageIndex = 1
      //   this.highCameraList = []
      //   this.fnGetHighCameraList()
      // } else if (this.device_type === 2) {
      //   this.videoPileForm.PageIndex = 1
      //   this.videoPileList = []
      //   // 获取车牌识别视频桩设备列表
      //   this.fnGetVideoPileList()
      // } else if (this.device_type === 3) {
      //   this.geomagneticForm.PageIndex = 1
      //   this.geomagneticList = []
      //   //  获取地磁设备列表
      //   this.fnGetGeomagneticList()
      // }
      // this.fnGetlnParkingDeviceConfigList()
      // this.fnGetlnParkingDeviceNotConfigList()
    }
  }
}
</script>

<style scoped lang="less">
.box-card {
  margin-top: -10px !important;
}

.content_box {
  display: flex;

  // padding: 20px;
  #left_box {
    width: 25%;
  }

  /deep/ .right_box {
    width: 70%;

    .config_table {
      .el-table__body-wrapper {
        overflow-y: auto;
        max-height: 150px;
      }
    }

    .noconfig_table {
      .el-table__body-wrapper {
        overflow-y: auto;
        max-height: 200px;
      }
    }
  }
}

.rowTitle {
  display: inline-block;
  width: 100%;
  height: 40px;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
}
.deciveList{
  padding-left: 50px;
  box-sizing: border-box;
  font-size: 18px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #fff;
  height: 60px;
  line-height: 60px;
  background-image: url('~@/assets/img/parkTopbgp.png');
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
// #left_box {
//   width: 40%;
//   // .el-menu {
//   //   margin-top: 15px;
//   //   background-color: #f5f5f5;
//   //   border-radius: 5px;
//   //   .el-submenu {
//   //     margin-bottom: 5px;
//   //     .el-menu-item {
//   //       border: 1px solid #ccc;
//   //       padding: 0px !important;
//   //       background-color: #000;
//   //     }
//   //     /deep/ .el-menu-item:last-child {
//   //       margin-bottom: 0;
//   //     }
//   //     /deep/ .el-menu-item-group__title {
//   //       padding: 0;
//   //     }
//   //     /deep/ .el-submenu__title {
//   //       font-size: 16px;
//   //       font-weight: 500;
//   //       background-color: green;
//   //     }
//   //     /deep/ .el-submenu__title i {
//   //       font-size: 16px;
//   //     }
//   //   }
//   // }
// }

.el-divider--vertical {
  height: 100% !important;
  margin: 15px;
  border-radius: 20px;
}

.my_menu {
  border-right: none;
  /deep/ .el-submenu {
    margin-bottom: 10px;

    .el-submenu__title {
      height: 30px;
      padding: 0px 10px 0 30px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: black;
      // background-color: #f5f5f5;
      border-radius: 5px;

      .iconfont {
        font-size: 20px;
        color: #cbcbcb;
      }
      .el-submenu__icon-arrow{
        right: unset;
        left: 5px;
      }
      // .el-submenu__icon-arrow.el-icon-arrow-down {
      //   display: none;
      // }
    }

    .el-menu-item-group {
      max-height: 400px !important;

      .el-menu-item {
        height: 30px;
        min-width: 100%;
        padding: 0 20px 0 40px !important;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .is-active {
        color: #01a6fb;
      }
    }
  }
  // 激活样式
  /deep/.el-submenu.is-active.is-opened {
    .el-submenu__title {
      color: black;
      // background-color: #f5f5f5;
      .iconfont {
        color: #fff;
      }
    }
  }
}
.el-divider--horizontal {
  margin: 0 !important;
  margin-bottom: 10px !important;
}
// .el-menu {
//   margin-top: 20px;
//   text-align: left;
//   margin-left: 0px;
//   :active {
//     .el-submenu__title {
//       background-color: #01a6fb;
//     }
//   }

//   .el-submenu {
//     padding: 0px;
//     margin-bottom: 10px;
//     .el-submenu__title {
//       background-color: #f5f5f5;
//     }
//   }

//   .text_span {
//     color: #cccccc;
//     margin-right: 20px;
//   }

//   .text_span1 {
//     color: #cccccc;
//     margin-right: 50px;
//     text-align: right;
//   }
//   .el-menu-item,
//   .el-submenu__title {
//     text-align: left;
//     padding-left: 5px;
//   }
//   .el-submenu__icon-arrow,
//   .el-icon-arrow-down &::before {
//     display: none;
//   }
// }
</style>
