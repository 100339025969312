<template>
  <!-- 路外 设备配置 -->
  <div class="box-card">
    <div class="content_box">
      <div class="left_box">
        <div class="title">停车场架构</div>
        <el-divider></el-divider>
        <el-tree :data="outParkingTreeList" ref="myTree" node-key="id" :props="defaultProps" default-expand-all
          :expand-on-click-node="false" @node-click="handleNodeClick">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span class="custom-tree-node-font" :style="data.type == 2
                ? (bindId
                  ? data.id == bindId
                  : data.id == parkingId)
                  ? 'font-weight: 700;color: #000;color:#409EFF;'
                  : 'font-weight: 700;color: #000;'
                : (bindId
                  ? data.id == bindId
                  : data.id == parkingId)
                  ? 'color:#409EFF;'
                  : ''
              ">{{ node.label }}</span>
          </span>
        </el-tree>
      </div>
      <el-divider direction="vertical" class="vertical_devider"></el-divider>
      <div class="right_box">
        <el-table :data="outParkingList" align="center" header-align="center" tooltip-effect="dark" border stripe
          v-if="treeType === 2">
          <el-table-column prop="parkposition" label="停车场"></el-table-column>
          <el-table-column prop="parkname" label="出入口"></el-table-column>
          <el-table-column prop="cartype" label="车牌识别设备">
            <template slot-scope="scope">
              <div :style="scope.row.cameraname === '未配置' ? 'color:#aaa;' : ''">
                {{ scope.row.cameraname }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="video" label="视频监控">
            <template slot-scope="scope">
              <div :style="scope.row.video === '未配置' ? 'color:#aaa;' : ''">
                {{ scope.row.video }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="allinonepcname" label="支付一体机">
            <template slot-scope="scope">
              <div :style="scope.row.allinonepcname === '未配置' ? 'color:#aaa;' : ''
                ">
                {{ scope.row.allinonepcname }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-form :model="configForm" ref="configFormRef" label-width="150px" class="configForm"
          v-else-if="treeType === 3">
          <div class="title">【车牌识别设备】</div>
          <el-divider></el-divider>
          <div v-for="(item, index) in configForm.cameraList" :key="index">
            <el-form-item label="车牌识别相机：" :prop="`cameraList[${index}].cameraname`" :rules="[
              {
                required: true,
                message: '请选择车牌识别相机',
                trigger: 'blur'
              }
            ]">
              <el-select popper-class="my-select" v-model="item.cameraname" placeholder="请选择车牌识别相机" filterable
                @change="cameralsChange(index, item.cameraname)" clearable>
                <el-option v-for="item in cameralsSelectList" :key="item._id" :label="item.name"
                  :value="item.name"></el-option>
              </el-select>
              <el-button style="margin-left:15px;padding:8px!important;" icon="el-icon-plus" size="mini" circle
                @click="addcamerals" v-if="index == 0"></el-button>
              <el-button style="margin-left:15px;padding:8px!important;" icon="el-icon-minus" size="mini" circle
                @click="delcamerals(index, item.cameraid)" v-if="index == 1"></el-button>
            </el-form-item>
          </div>
          <!-- <el-form-item
              label="出入口类型："
              prop="cameraType"
              :rules="[
                { required: true, message: '请选择出入口类型', trigger: 'blur' }
              ]"
            >
              <el-select
                popper-class="my-select"
                v-model="configForm.cameraType"
                placeholder="请选择出入口类型"
              >
                <el-option
                  v-for="item in getOptions('IdentifycameraType')"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item> -->
          <div class="title">【其它设备】</div>
          <el-divider></el-divider>
          <el-form-item label="视频监控相机：">
            <el-select popper-class="my-select" v-model="configForm.videoname" placeholder="请选择视频监控设备" filterable
              @change="videoidChange" clearable>
              <el-option v-for="item in monitorSelectList" :key="item._id" :label="item.name"
                :value="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付一体机：">
            <el-select popper-class="my-select" v-model="configForm.allinonepcname" placeholder="请选择支付一体机设备" filterable
              @change="allinonepcidChange" clearable>
              <el-option v-for="item in integratedSelectList" :key="item._id" :label="item.name"
                :value="item.name"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" @click="submitConfigForm" class="checkButton" v-preventReClick>保 存
    </lbButton>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapMutations, mapState } from 'vuex'
import {
  getOutParkingTreeList,
  getEquipmentConfigDetail,
  getAllEquipmentConfigList,
  getIdentifyCameralsSelectList,
  setOutParkingDeviceLayout,
  getDisplayDevicelsSelectList,
  getIntegratedMachinelsSelectList,
  getMonitorscenelsSelectList
} from '@/api/outParking'
export default {
  props: ['id', 'Parkname'],
  data () {
    return {
      // 当前展示的节点
      bindId: '',
      oldBindId: '',
      treeType: 2, //  2.停车场节点 3.出入口节点
      alleywayId: '', // 出入口id;
      oldAlleywayId: '', // 上一次点击的出入口id
      // 停车场树结构
      outParkingTreeList: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      // 停车场结构列表
      outParkingList: [],
      batchAddFormRules: {
        parking_name: [
          { required: true, message: '请输入停车场名称', trigger: 'blur' }
        ],
        berth_num: [
          { required: true, message: '请输入总车位数', trigger: 'blur' },
          {
            pattern: /^\+?[1-9][0-9]*$/,
            message: '总车位数必须为不能为0的正整数',
            trigger: 'blur'
          }
        ],
        position: [
          { required: true, message: '请标注所在位置', trigger: 'change' }
        ]
      },
      // 配置表单
      configForm: {
        ParentId: '', // 父级id
        ParentName: '', // 父级名称
        PrakingID: '', // 停车场id（当前选中的id）
        PrakingName: '', // 停车场名称
        cameraType: '', // 出入场类型
        cameraList: [
          {
            cameraid: '', // id
            cameraname: '', // 名称
            camerasn: '' // 序列号
          }
        ],
        // cameraid: "", // 车牌识别相机1id
        // cameraname: "", // 车牌识别相机1名称
        // cameratid: "", // 车牌识别相机2id
        // cameratname: "", // 车牌识别相机2名称
        // camerasn: [], // 车牌识别相机序列号集合
        allinonepcid: '', // 一体机id
        allinonepcname: '', // 一体机名称
        allinonepcsn: '',
        videoid: '', // 监控设备id
        videosn: '', // 监控设备序列号
        videoname: '' // 监控设备名称
      },
      // 车牌识别相机设备
      cameralsSelectList: [],
      cameralsAllList: [],

      // 一体机
      integratedSelectList: [],
      integratedAllList: [],
      // 视频监控
      monitorSelectList: [],
      monitorAllList: [],
      // 配置数据列表
      configList: []
    }
  },
  created () {
    this.bindId = this.id
    this.fnGetOutParkingTreeList()
    this.fnGetAllSelectList()
    this.fnGetAllEquipmentConfigList(this.id)
  },
  mounted () { },
  watch: {
    alleywayId (newval, oldval) {
      // // console.log('newval----', newval);
      // // console.log('oldval----', oldval);
      if (oldval && oldval !== newval) {
        this.oldAlleywayId = oldval
        // // console.log(this.oldAlleywayId);
      }
    }
  },
  computed: {
    ...mapGetters(['getOptions', 'getWayName'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 返回停车场信息页面
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    },
    // 获取停车场树结构
    async fnGetOutParkingTreeList () {
      const res = await getOutParkingTreeList({
        parkid: this.id
      })
      // // console.log('获取停车场树结构------', res);
      if (res && res.Code === 200 && res.Data) {
        this.outParkingTreeList = res.Data
      } else {
        this.outParkingTreeList = []
      }
    },
    // 获取所有设备下拉列
    fnGetAllSelectList () {
      var that = this
      axios
        .all([
          this.fnGetcameralsSelectList(),
          this.fnGetIntegratedMachinelsSelectList(),
          this.fnGetMonitorscenelsSelectList()
        ])
        .then(
          axios.spread(function (camerals, integrated, monitor) {
            // // console.log('车牌识别相机----', camerals);
            that.cameralsAllList =
              camerals && camerals.Code === 200 ? camerals.Data : []
            that.cameralsSelectList = that.cameralsAllList.concat()

            // // console.log('一体机----', integrated);
            that.integratedAllList =
              integrated && integrated.Code === 200 ? integrated.Data : []
            that.integratedSelectList = that.integratedAllList.concat()

            // // console.log('视频监控----', monitor);
            that.monitorAllList =
              monitor && monitor.Code === 200 ? monitor.Data : []
            that.monitorSelectList = that.monitorAllList.concat()
          })
        )
    },
    // 获取车牌识别相机下拉框
    async fnGetcameralsSelectList () {
      return await getIdentifyCameralsSelectList()
    },
    // 获取显示屏设备下拉框
    async fnGetDisplayDevicelsSelectList () {
      return await getDisplayDevicelsSelectList()
    },
    // 获取一体机设备下拉框
    async fnGetIntegratedMachinelsSelectList () {
      return await getIntegratedMachinelsSelectList()
    },
    // 获取视频监控设备下拉框
    async fnGetMonitorscenelsSelectList () {
      return await getMonitorscenelsSelectList()
    },
    // 车牌识别相机下拉列发生改变时
    cameralsChange (index, name) {
      if (name) {
        // // console.log(index, name);
        // 查询当前车牌识别相机所在位置，并赋值
        var findIndex = this.cameralsAllList.findIndex(
          item => item.name === name
        )
        this.configForm.cameraList[index].cameraid = this.cameralsAllList[
          findIndex
        ]._id
        this.configForm.cameraList[index].camerasn = this.cameralsAllList[
          findIndex
        ].serial
        var list = this.configForm.cameraList.concat()
        this.cameralsSelectList = this.cameralsAllList.concat()
        if (this.configList.length > 0) {
          this.configList.forEach(item => {
            list.push(...item.cameraList)
          })
        }
        list.forEach(item => {
          this.cameralsSelectList = this.cameralsSelectList.filter(i => {
            return i._id !== item.cameraid
          })
        })
      } else {
        const item = this.cameralsAllList.find(
          item => item._id === this.configForm.cameraList[index].cameraid
        )
        this.cameralsSelectList.push(item)
        this.configForm.cameraList[index].cameraid = ''
        this.configForm.cameraList[index].camerasn = ''
      }
    },
    // 新增车牌识别相机配置项
    addcamerals () {
      // // console.log(this.configForm.cameraList);
      if (this.configForm.cameraList.length < 2) {
        this.configForm.cameraList.push({
          cameraid: '', // id
          cameraname: '', // 名称
          camerasn: '' // 序列号
        })
        this.configForm.cameraList = this.configForm.cameraList.map(
          item => item
        )
      }
      // console.log(this.configForm.cameraList);
    },
    // 删除车牌识别相机配置荐
    delcamerals (index, id) {
      var arr = this.cameralsAllList.filter(i => {
        return i._id === id
      })
      this.cameralsSelectList = this.cameralsSelectList.concat(arr)
      this.configForm.cameraList.splice(index, 1)
      if (!id) return
    },
    // 设备类型下拉框发生改变时
    // devicetypeChange(val) {
    //   if (val === 1) {
    //     this.configForm.allinonepcid = '';
    //     this.configForm.allinonepcname = '';
    //     this.configForm.allinonepcsn = '';
    //   } else if (val === 2) {
    //     this.configForm.cameraList = [
    //       {
    //         cameraid: '', // id
    //         cameraname: '', // 名称
    //         camerasn: '' // 序列号
    //       }
    //     ]
    //     this.configForm.displayid = "";
    //     this.configForm.displayname = "";
    //     this.configForm.displaysn = "";
    //   }
    // },
    // 一体机下拉框发生改变时
    allinonepcidChange (val) {
      console.log('allinonepcidChange---', val)
      if (val) {
        const index = this.integratedSelectList.findIndex(
          item => item.name === val
        )
        this.configForm.allinonepcid = this.integratedSelectList[index]._id
        this.configForm.allinonepcsn = this.integratedSelectList[index].serial
        var i = this.configList.findIndex(
          item => item.PrakingID === this.bindId
        )
        // console.log(i);
        if (i < 0) {
          var list = this.integratedAllList.concat()
          this.integratedSelectList = list.filter(item => item.name !== val)
        } else {
          this.configList[i].allinonepcid = this.configForm.allinonepcid
          this.configList[i].allinonepcname = this.configForm.allinonepcname
          this.configList[i].allinonepcsn = this.configForm.allinonepcsn
          this.resetDiviceList()
        }
      } else {
        const item = this.integratedAllList.find(
          item => item._id === this.configForm.allinonepcid
        )
        this.integratedSelectList.push(item)
        this.configForm.allinonepcid = ''
        this.configForm.allinonepcsn = ''
      }
    },
    // 监控设备下拉框发生改变时
    videoidChange (val) {
      if (val) {
        var index = this.monitorSelectList.findIndex(item => item.name === val)
        this.configForm.videoid = this.monitorSelectList[index]._id
        this.configForm.videosn = this.monitorSelectList[index].serial
        // // console.log(this.configForm);
        var i = this.configList.findIndex(
          item => item.PrakingID === this.bindId
        )
        if (i < 0) {
          var list = this.monitorAllList.concat()
          this.monitorSelectList = list.filter(item => item.name !== val)
        } else {
          this.configList[i].videoid = this.configForm.videoid
          this.configList[i].videosn = this.configForm.videosn
          this.configList[i].videoname = this.configForm.videoname
          this.resetDiviceList()
        }
      } else {
        const item = this.monitorAllList.find(
          item => item._id === this.configForm.videoid
        )
        this.monitorSelectList.push(item)
        this.configForm.videoid = ''
        this.configForm.videosn = ''
      }
    },
    // 获取停车场设备配置数据
    async fnGetAllEquipmentConfigList (id) {
      const res = await getAllEquipmentConfigList({
        prakingid: id
      })
      console.log('获取停车场数据---', res)
      if (res && res.Code === 200 && res.Data) {
        this.outParkingList = res.Data
      } else {
        this.outParkingList = []
      }
    },
    // 点击树节点触发
    handleNodeClick (data, node, key) {
      // console.log('点击树节点触发----data-----', data)
      // console.log('点击树节点触发----node-----', node)
      // console.log('点击树节点触发----key-----', key)
      const nowClickData = data
      if (this.bindId === data.id) return
      this.treeType = data.type
      this.bindId = data.id
      if (data.type === 2) {
        this.fnGetAllEquipmentConfigList(data.id)
      } else if (data.type === 3) {
        this.alleywayId = data.id
        this.handleData(data.id, nowClickData)
        // this.fnGetOutParkingDeviceConfigList(data.id);
        // // console.log('configList----', this.configList);
      }
    },
    handleData (id, nowData) {
      console.log('nowData', nowData)
      // console.log('oldAlleywayId-----', this.$refs.myTree);
      var that = this
      this.$nextTick(() => {
        // 获取上一次点击出入节点node
        // 若存在，则处理上一出入节点的数据
        // 若不存在，则获取当前展示节点的数据
        const oldNode = that.$refs.myTree.getNode(this.oldAlleywayId)
        if (!oldNode) {
          console.log('无oldNode', oldNode)
          return that.fnGetOutParkingDeviceConfigList(id, nowData)
        } else {
          console.log('有oldNode', oldNode)
          that.configForm.PrakingID = oldNode.data.id
          that.configForm.PrakingName = oldNode.data.title
          that.configForm.ParentId = oldNode.parent.data.id
          that.configForm.ParentName = oldNode.parent.data.title
          // that.configForm.cameraType =
          that.addArrayList(id, nowData)
        }
      })
    },
    // 添加配置数据
    addArrayList (id, nowData) {
      // 判断是否存在配置
      var index = this.configList.findIndex(
        item => item.PrakingID === this.configForm.PrakingID
      )
      // // console.log('添加配置数据-----', index);
      if (index < 0) {
        this.configList.push(this.configForm)
      } else {
        this.configList[index] = this.configForm
      }
      // 重置设备下拉框
      this.resetDiviceList()
      // this.resetConfigForm()
      this.fnGetOutParkingDeviceConfigList(id, nowData)
    },
    // 重置设备下拉框
    resetDiviceList () {
      // 重置设备下拉框为原始数据
      this.integratedSelectList = this.integratedAllList.concat()
      this.monitorSelectList = this.monitorAllList.concat()
      this.cameralsSelectList = this.cameralsAllList.concat()
      // 删除已经保存在配置数据中的设备
      this.configList.forEach(item => {
        this.integratedSelectList = this.integratedSelectList.filter(
          data2 => data2._id !== item.allinonepcid
        )
        this.monitorSelectList = this.monitorSelectList.filter(
          data3 => data3._id !== item.videoid
        )
        item.cameraList.forEach(camera => {
          this.cameralsSelectList = this.cameralsSelectList.filter(
            data4 => data4._id !== camera.cameraid
          )
        })
      })
    },
    // 重置表单数据
    resetConfigForm () {
      this.configForm = {
        ParentId: '',
        ParentName: '',
        PrakingID: '', // 停车场id（当前选中的id）
        PrakingName: '', // 停车场名称
        cameraType: '0',
        cameraList: [
          {
            cameraid: '', // id
            cameraname: '', // 名称
            camerasn: '' // 序列号
          }
        ],
        allinonepcid: '', // 一体机id
        allinonepcname: '', // 一体机名称
        allinonepcsn: '',
        videoid: '', // 监控设备id
        videosn: '', // 监控设备序列号
        videoname: '' // 监控设备名称
      }
    },
    // 获取设备配置列表
    async fnGetOutParkingDeviceConfigList (id, nowData) {
      // 判断该节点是否在configList存在数据，
      // 若存在，则将configList中属于该节点下的数据赋值到表单中
      // 若不存在， 则获取出入口节点下绑定的设备数据赋值到表单中，并处理设备下拉框中数据
      var index = this.configList.findIndex(item => item.PrakingID === id)
      // // console.log(index);
      if (index >= 0) {
        this.configForm = this.configList[index]
        // console.log('configForm-------', this.configForm)
      } else {
        const res = await getEquipmentConfigDetail({
          prakingid: id
        })
        if (res && res.Code === 200 && res.Data) {
          // this.configForm = res.Data;
          console.log('res.Data', res.Data)
          this.configForm.ParentId = res.Data.ParentId
          this.configForm.ParentName = res.Data.ParentName
          this.configForm.PrakingID = res.Data.PrakingID
          this.configForm.PrakingName = res.Data.PrakingName
          this.configForm.cameraType = res.Data.cameraType
            ? res.Data.cameraType
            : null
          this.configForm.displayid = res.Data.displayid
          this.configForm.displayname = res.Data.displayname
          this.configForm.displaysn = res.Data.displaysn
          this.configForm.cameraList = []
          if (res.Data.camerasn && res.Data.camerasn.length > 0) {
            res.Data.camerasn.forEach((item, index) => {
              this.configForm.cameraList.push({
                cameraid: index === 0 ? res.Data.cameraid : res.Data.cameratid,
                cameraname:
                  index === 0 ? res.Data.cameraname : res.Data.cameratname,
                camerasn: item
              })
              this.cameralsAllList.push({
                name: index === 0 ? res.Data.cameraname : res.Data.cameratname,
                serial: item,
                _id: index === 0 ? res.Data.cameraid : res.Data.cameratid
              })
            })
          }
          this.configForm.allinonepcid = res.Data.allinonepcid
          this.configForm.allinonepcname = res.Data.allinonepcname
          this.configForm.allinonepcsn = res.Data.allinonepcsn
          this.integratedAllList.push({
            name: res.Data.allinonepcname,
            serial: res.Data.allinonepcsn,
            _id: res.Data.allinonepcid
          })
          this.configForm.videoid = res.Data.videoid
          this.configForm.videosn = res.Data.videosn
          this.configForm.videoname = res.Data.videoname
          this.monitorAllList.push({
            name: res.Data.videoname,
            serial: res.Data.videosn,
            _id: res.Data.videoid
          })
          this.cameralsAllList = this.delRepetition(this.cameralsAllList)
          this.integratedAllList = this.delRepetition(this.integratedAllList)
          this.monitorAllList = this.delRepetition(this.monitorAllList)
        } else {
          this.resetConfigForm()
          this.$nextTick(() => {
            this.configForm.cameraType = nowData.cameraType
          })
        }
      }
    },
    // 去重
    delRepetition (list) {
      var newList = []
      var obj = {}
      list.forEach(item => {
        if (!obj[item._id]) {
          newList.push(item)
          obj[item._id] = true
        }
      })
      // // console.log(newList);
      return newList
    },
    // 保存配置
    submitConfigForm () {
      if (!this.$refs.configFormRef) {
        return this.$emit('closeDialog', false)
      }
      this.$refs.configFormRef.validate(valid => {
        if (valid) {
          const node = this.$refs.myTree.getNode(this.bindId)
          // console.log('node', node)
          this.configForm.ParentId = node.parent.data.id
          this.configForm.ParentName = node.parent.data.title
          this.configForm.PrakingID = node.data.id
          this.configForm.PrakingName = node.data.title
          this.configForm.cameraType = node.data.cameraType
          // 判断是否存在配置
          var index = this.configList.findIndex(
            item => item.PrakingID === this.configForm.PrakingID
          )
          if (index < 0) {
            this.configList.push(this.configForm)
          } else {
            this.configList[index] = this.configForm
          }
          // 重置设备下拉框
          this.resetDiviceList()
          this.fnSetOutParkingDeviceLayout()
        } else {
          return false
        }
      })
    },
    // 请求接口
    async fnSetOutParkingDeviceLayout () {
      var arr = []
      this.configList.forEach(item => {
        var obj = {}
        obj.ParprakingID = this.id
        obj.ParprakingName = this.Parkname
        obj.ParentId = item.ParentId
        obj.ParentName = item.ParentName
        obj.PrakingID = item.PrakingID
        obj.PrakingName = item.PrakingName
        if (item.videoid) {
          obj.videoid = item.videoid
          obj.videosn = item.videosn
          obj.videoname = item.videoname
        }
        obj.cameraType = item.cameraType
        if (item.allinonepcid) {
          obj.allinonepcid = item.allinonepcid
          obj.allinonepcname = item.allinonepcname
          obj.allinonepcsn = item.allinonepcsn
        }
        if (item.cameraList[0].cameraid) {
          obj.cameraid = item.cameraList[0].cameraid
          obj.cameraname = item.cameraList[0].cameraname
          obj.camerasn = [item.cameraList[0].camerasn]
        }
        if (item.cameraList.length > 1 && item.cameraList[1].cameraid) {
          obj.cameratid = item.cameraList[1].cameraid
          obj.cameratname = item.cameraList[1].cameraname
          obj.camerasn.push(item.cameraList[1].camerasn)
        }
        if (obj.camerasn && obj.camerasn.length > 0) {
          arr.push(obj)
        }
      })
      if (arr.length > 0) {
        const res = await setOutParkingDeviceLayout(arr)
        if (res && res.Code === 200) {
          console.log('配置修改', res)
          this.$emit('closeDialog', false)
          //   this.goBack()
        }
      } else {
        this.$message.warning('请配置设备！')
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>
<style scoped lang="less">
.title {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.content_box {
  display: flex;
  justify-content: flex-start;
  min-height: 400px;

  .left_box {
    width: 30%;
    text-align: left;
  }

  .vertical_devider {
    height: inherit !important;
    margin: 0 20px !important;
  }

  .right_box {
    width: 65%;
    text-align: left;

    .configForm {
      .el-select {
        width: 400px;
      }
    }
  }
}

.box-card {
  position: relative;
  padding-bottom: 30px;
  margin-top: 0 !important;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }
}
.custom-tree-node-font{
  font-size: 15px;
}
</style>
