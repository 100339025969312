<!-- 车场管理-停车场信息 -->
<template>
  <div class="ParkingInformation">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="停车场：">
        <el-input v-model="queryForm.parking_name" maxlength="20" @keyup.enter.native="onSubmit" placeholder="请输入停车场名称"
          @input="(e) => (queryForm.parking_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="运营状态：">
        <el-select popper-class="my-select" clearable v-model="queryForm.status" placeholder="请选择运营状态"
          @clear="queryForm.status = null" @change="onSubmit()">
          <el-option label="运营中" :value="1"></el-option>
          <el-option label="已停运" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="车场信息">
      <template slot="heard-right">
        <div v-if="isCurPark">
          <!-- 路内操作 -->
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('53b7cd06f90c4d9e8bd98b58')">删除
          </lbButton>
          <lbButton icon="xinzeng" @click="batchAdd"
            v-if="this.getUserInfo.user_name != 'admin' && isShowBtn('2fc863839cb447fda0671f6c')">新增</lbButton>
        </div>
        <div v-else>
          <!-- 路外操作 -->
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('a10da6320a4346709bf9ea62')">删除
          </lbButton>
          <lbButton icon="xinzeng" v-if="this.getUserInfo.user_name != 'admin' &&
            isShowBtn('121a15ebd2aa40d19226dbdb')" @click="batchAdd">新增</lbButton>
        </div>
      </template>
      <div class="box-card">
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
              <template slot-scope="{row}" slot="status" >
                <lebo-switch :status="row.status == 0" :turnOn="'运营中'" :turnOff="'已停运'" :switchType="row.status"
                @switchChange="changeStatus(row)"></lebo-switch>
            </template>
            <template slot-scope="{row}" slot="tcpservice_online"  v-if="!isCurPark">
              <div :style="row.tcpservice_online
                ? 'color: #18943b;'
                : 'color: #ff0000;'
                ">
                {{ row.tcpservice_online ? "在线" : "离线" }}
              </div>
            </template>
              <template slot-scope="{row}" slot="create_time">
                {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <div v-if="isCurPark">
                <lbButton type="succes" icon="chakan" hint="查看" @click="lookCurParking(row)"></lbButton>
                <lbButton type="warning" icon="bianji" hint="编辑" @click="batchEdit(row._id)"
                  v-if="isShowBtn('b5b7042de9674947a40d7e65')"></lbButton>
                <lbButton type="setting" icon="shebeipeizhi" hint="设备配置" @click="devicelLayout(row)"
                  v-if="isShowBtn('7251e77e10404cbd895781e9')"></lbButton>
                <lbButton type="err" icon="jifeipeizhi" hint="计费配置" @click="billingConfiguration(row)"
                  v-if="isShowBtn('494738ed1fcd48db90f952b5')"></lbButton>
              </div>
              <div v-else>
                <lbButton type="succes" icon="chakan" hint="查看" @click="lookParking(row)"></lbButton>
                <lbButton type="warning" icon="bianji" hint="编辑" @click="batchEdit(row._id)"
                  v-if="isShowBtn('a6da01497e34437799a0e978')"></lbButton>
                <lbButton type="info" icon="churukou" hint="通行策略" @click="parkingAccessStrategy(row._id)"
                  v-if="isShowBtn('e97deef7a50b503e8122cb6b')"></lbButton>
                <lbButton type="setting" icon="shebeipeizhi" hint="设备配置" @click="deviceSetting(row)"
                  v-if="isShowBtn('f879f073791a4004b2065625')"></lbButton>
                <lbButton type="err" icon="jifeipeizhi" hint="计费配置" @click="billingConfiguration(row)"
                  v-if="isShowBtn('08426c12ade54920a1a03fbd')"></lbButton>
              </div>
            </template>
          </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" :fit="true"
          @selection-change="handleSelectionChange" id="tableList" stripe align="center" border header-align="center">
          <el-table-column type="selection" width="50" ></el-table-column>
          <el-table-column prop="tcpservice_device_no" label="车场ID" v-if="!isCurPark" width="200">
            <template slot-scope="scope">
              {{ scope.row.tcpservice_device_no ? scope.row.tcpservice_device_no : scope.row._id }}
              </template>
          </el-table-column>
          <el-table-column prop="parking_name" label="停车场" ></el-table-column>
          <el-table-column prop="position" label="所在位置" ></el-table-column>
          <el-table-column prop="berth_num" label="总车位数"></el-table-column>
          <el-table-column prop="over_berth_num" label="剩余车位数"></el-table-column>
          <el-table-column prop="status" label="运行状态"  v-if="(isShowBtn('404738ed3f0d40db90f952b5') && isCurPark) ||(isShowBtn('e97de3f7a5bb403e8a82cb6b') && !isCurPark)">
            <template slot-scope="scope">
              <lebo-switch :status="scope.row.status == 0" :turnOn="'运营中'" :turnOff="'已停运'" :switchType="scope.row.status"
                @switchChange="changeStatus(scope.row)"></lebo-switch>
            </template>
          </el-table-column>
          <el-table-column label="状态"  v-if="!isCurPark">
            <template slot-scope="scope">
              <div :style="scope.row.tcpservice_online
                ? 'color: #18943b;'
                : 'color: #ff0000;'
                ">
                {{ scope.row.tcpservice_online ? "在线" : "离线" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="150" >
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
              <div v-if="isCurPark">
                <!-- 路内操作 -->
                <lbButton type="succes" icon="chakan" hint="查看" @click="lookCurParking(scope.row)"></lbButton>
                <lbButton type="warning" icon="bianji" hint="编辑" @click="batchEdit(scope.row._id)"
                  v-if="isShowBtn('b5b7042de9674947a40d7e65')"></lbButton>
                <lbButton type="setting" icon="shebeipeizhi" hint="设备配置" @click="devicelLayout(scope.row)"
                  v-if="isShowBtn('7251e77e10404cbd895781e9')"></lbButton>
                <lbButton type="err" icon="jifeipeizhi" hint="计费配置" @click="billingConfiguration(scope.row)"
                  v-if="isShowBtn('494738ed1fcd48db90f952b5')"></lbButton>
              </div>
              <div v-else>
                <!-- 路外操作 -->
                <lbButton type="succes" icon="chakan" hint="查看" @click="lookParking(scope.row)"></lbButton>
                <lbButton type="warning" icon="bianji" hint="编辑" @click="batchEdit(scope.row._id)"
                  v-if="isShowBtn('a6da01497e34437799a0e978')"></lbButton>
                <lbButton type="info" icon="churukou" hint="通行策略" @click="parkingAccessStrategy(scope.row._id)"
                  v-if="isShowBtn('e97deef7a50b503e8122cb6b')"></lbButton>
                <lbButton type="setting" icon="shebeipeizhi" hint="设备配置" @click="deviceSetting(scope.row)"
                  v-if="isShowBtn('f879f073791a4004b2065625')"></lbButton>
                <lbButton type="err" icon="jifeipeizhi" hint="计费配置" @click="billingConfiguration(scope.row)"
                  v-if="isShowBtn('08426c12ade54920a1a03fbd')"></lbButton>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 页码 -->
        <el-pagination class="footPagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </le-card>
    <!-- 开启或者关闭停车场对话框 -->
    <lebo-dialog append-to-body :title="(row.status === 0 ? '开启' : '关闭') + '停车场'" :isShow="batchChangDialogVisible"
      width="35%" @close=" batchChangDialogVisible = false" top="10%" footerSlot>
      <p style="text-align: left;text-indent:2em;" v-if="row.status === 0">
        开启后，停车场所有功能可正常使用，确定立即开启 " {{ row.parking_name }} " 吗？
      </p>
      <p style="text-align: left;text-indent:2em;" v-else>
        关闭后，停车场所有功能无法使用，确定立即关闭 " {{ row.parking_name }} " 吗？
      </p>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click=" batchChangDialogVisible = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click=" setlnParkingStatus()">{{ row.status === 0 ? '开 启' : '关 闭' }}
        </lbButton>
      </span>
    </lebo-dialog>
    <!-- 查看路内/路外停车场 -->
    <lebo-dialog title="查看停车场" width="80%" class="showLookParking" :isShow="showLookParkDetail" closeOnClickModal
      @close="showLookParkDetail = false" footerSlot>
      <lookParkDetail v-if="showLookParkDetail" :parkingId="dialogParkingId" :inOutPark="isCurOrOutPark"></lookParkDetail>
    </lebo-dialog>
    <!-- 路内设备配置 -->
    <lebo-dialog title="设备配置" width="80%" :isShow="showCurDevicelLayout" :footerSlot="true"
      @onConfirm="showCurDevicelLayout = false" @close="closeDialog" closeOnClickModal>
      <devicelLayout v-if="showCurDevicelLayout" :parking_id="dialogParkingId" :parking_name="dialogParking_name">
      </devicelLayout>
    </lebo-dialog>
    <!-- 路内/路外计费配置 -->
    <lebo-dialog title="计费配置" width="80%" :isShow="showBillingConfiguration" :footerSlot="true"
      @onConfirm=" showBillingConfiguration = false" @close="closeDialog" closeOnClickModal>
      <billingConfiguration v-if="showBillingConfiguration" :parkingId="dialogParkingId"
        :parking_name="dialogParking_name"></billingConfiguration>
    </lebo-dialog>
    <!-- 新增路内/路外停车场 -->
    <lebo-dialog title="新增停车场" width="40%" class="showAddEditParking" :isShow="showAddEditParking"
      @close="showAddEditParking = false" footerSlot>
      <addEditPark v-if="showAddEditParking" @closeDialog="closeDialog" :inOutPark="isCurOrOutPark"></addEditPark>
    </lebo-dialog>
    <!-- 编辑路内/路外停车场 -->
    <lebo-dialog title="编辑停车场" width="40%" class="showEditOutParking" :isShow="showEditOutParking"
      @close="showEditOutParking = false" footerSlot>
      <addEditPark v-if="showEditOutParking" :id="dialogParkingId" :inOutPark="isCurOrOutPark" @closeDialog="closeDialog">
      </addEditPark>
    </lebo-dialog>
    <!-- 路外通行策略 -->
    <lebo-dialog title="通行策略" width="80%" :isShow="showparkingAccessStrategy" @close="showparkingAccessStrategy = false"
      footerSlot>
      <accessStrategy v-if="showparkingAccessStrategy" :parkId="dialogParkingId" @closeDialog="closeDialog">
      </accessStrategy>
    </lebo-dialog>
    <!-- 路外设备配置 -->
    <lebo-dialog title="设备配置" width="80%" :isShow="showDeciveSetting" @close="showDeciveSetting = false" footerSlot>
      <deviceSetting v-if="showDeciveSetting" :id="dialogParkingId" :Parkname="dialogParking_name"
        @closeDialog="closeDialog">
      </deviceSetting>
    </lebo-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import {
  getParkingList as getCurParkingList, getParkingList, addParkingInfo, updateParkingInfo, delParkingInfo as delCurParkingInfo, setParkingStatus, getParkingNameIsReuse
} from '@/api/curbParkapi'
import { getParkingList as getOutParkingList, delParkingInfo as delOutParkingInfo, setOutParkingStatus } from '@/api/outParking'
import devicelLayout from '../devicelLayout/index.vue'
import billingConfiguration from '../billingConfiguration/index.vue'
import addEditPark from '../addPark/index.vue'
import accessStrategy from '../accessStrategy/index.vue'
import deviceSetting from '../deviceSetting/index.vue'
import lookParkDetail from '../lookParkDetail/index.vue'
export default {
  name: '',
  components: { devicelLayout, billingConfiguration, addEditPark, accessStrategy, deviceSetting, lookParkDetail },
  props: {
    isCurPark: { type: Boolean }
  },
  data () {
    // 是否为数字
    var isNumber = (rule, value, callback) => {
      const isNumberReg = /^\+?[1-9][0-9]*$/
      if (isNumberReg.test(value)) {
        return callback()
      }
      callback(new Error())
    }
    // 是否为特殊字符
    var isSpecialChar = (rule, value, callback) => {
      const isSpecialCharReg = /^[`~!@@#$%^&*()_\-+=<>?:"{}|./'\\[\]·~@@#￥%……&*（）——\-+={}|《》【】；。]*$/
      if (!isSpecialCharReg.test(value)) {
        return callback()
      }
      callback(new Error())
    }
    return {
      // 查询表单
      queryForm: {
        parking_name: '',
        PageSize: 10,
        PageIndex: 1,
        // 运营状态 0:停止 1:开启 10：全部
        status: null
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 是否展示 开启/关闭停车场对话框
      batchChangDialogVisible: false,
      // 当前停车场信息
      row: '',
      dialogParkingId: '', // 路内/路外车场id
      showLookParkDetail: false, // 查看路内/路外车场弹框
      dialogParking_name: '', // 传入路内/路外车场名字
      showCurDevicelLayout: false, // 路内设备配置弹窗
      showBillingConfiguration: false, // 路内/路外计费配置弹窗
      showAddEditParking: false, // 新增路内/路外车场弹窗
      showEditOutParking: false, // 编辑路内/路外弹框
      showparkingAccessStrategy: false, // 路外通行策略
      showDeciveSetting: false, // 路外设备配置弹框
      isCurOrOutPark: '', // 路内外新增编辑弹框 outPark路外  curPark路内
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        menuWidth: 250, // 操作栏宽度
        tip: false,
        column: []
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  watch: {
    tableData: {
      handler (newV, oldV) {
        if (newV.length > 0) {
        } else {
          if (newV.length === oldV.length) return
          if (this.isCurPark) {
            // 路内
            this.fngetCurParkingList()
          } else {
            this.fnGetOutParkingList()
          }
        }
      }
    }

  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters(['getUserInfo'])
  },
  methods: {
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      if (this.isCurPark) {
        // 路内
        this.fngetCurParkingList()
      } else {
        this.fnGetOutParkingList()
      }
    },
    // 路内停车场列表查询
    async fngetCurParkingList () {
      const res = await getCurParkingList({
        parking_name: this.queryForm.parking_name ? this.queryForm.parking_name : '',
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        status: this.queryForm.status !== null && this.queryForm.status !== '' ? this.queryForm.status : 10
      })
      // console.log('res', res)
      if (res && res.Code === 200) {
        if (res.Data && res.Data.DataList.length) {
          this.tableData = res.Data.DataList
          this.total = res.Data.TotalCount
        } else {
          this.tableData = []
          this.total = 0
        }
      } else {
        this.tableData = []
        this.total = 0
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 路外停车场列表查询
    async fnGetOutParkingList () {
      // console.log(this.queryForm.status)
      const res = await getOutParkingList({
        parking_name: this.queryForm.parking_name
          ? this.queryForm.parking_name
          : '',
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        status:
          this.queryForm.status !== null && this.queryForm.status !== ''
            ? this.queryForm.status
            : 10
      })
      // console.log('res', res)
      if (res && res.Code === 200) {
        if (res.Data && res.Data.DataList.length) {
          this.tableData = res.Data.DataList
          this.total = res.Data.TotalCount
        } else {
          this.tableData = []
          this.total = 0
        }
      } else {
        this.tableData = []
        this.total = 0
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的停车场, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var arr = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i]._id)
            if (arr.length === this.multipleSelection.length) {
              obj._ids = arr
              this.delItem(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除！')
        })
      } else {
        this.$msg.warning('请选择要删除的停车场！')
      }
    },
    async delItem (obj) {
      let res = ''
      if (this.isCurPark) {
        res = await delCurParkingInfo(obj)
        this.fngetCurParkingList()
      } else {
        // 路外删除，刷新
        res = await delOutParkingInfo(obj)
        this.fnGetOutParkingList()
      }
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      var that = this
      setTimeout(() => {
        that.$store.dispatch('requestPorkingList')
      }, 300)
    },
    // 展示新增路内停车场对话框
    batchAdd () {
      if (this.isCurPark) {
        this.isCurOrOutPark = 'curPark'
      } else {
        this.isCurOrOutPark = 'outPark'
      }
      this.showAddEditParking = true
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      if (this.isCurPark) {
        this.fngetCurParkingList()
      } else {
        // 路外刷新
        this.fnGetOutParkingList()
      }
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   // this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   if (this.isCurPark) {
    //     this.fngetCurParkingList()
    //   } else {
    //     // 路外刷新
    //     this.fnGetOutParkingList()
    //   }
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      if (this.isCurPark) {
        this.fngetCurParkingList()
      } else {
        // 路外
        this.fnGetOutParkingList()
      }
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   if (this.isCurPark) {
    //     this.fngetCurParkingList()
    //   } else {
    //     // 路外
    //     this.fnGetOutParkingList()
    //   }
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    changeStatus (row, type) {
      this.row = row
      this.batchChangDialogVisible = true
    },
    // 新增/编辑路内
    submitAddForm () {
      // 对整个表单进行校验
      // console.log("=-=-=-=-that.batchAddForm = ", JSON.stringify(this.batchAddForm));
      this.$refs.batchAddFormRef.validate((valid) => {
        // 成功
        if (valid) {
          this.fngetParkingNameIsReuse()
        } else {
          return false
        }
      })
    },
    // 关闭或开启停车场
    async setlnParkingStatus () {
      this.batchChangDialogVisible = false
      var obj = {}
      obj._id = this.row._id
      obj.status = this.row.status === 0 ? 1 : 0
      let res = ''
      // 路内开启关闭
      if (this.isCurPark) {
        res = await setParkingStatus(obj)
        if (res && res.Code === 200) {
          this.$msg.success(res.Message)
        }
        this.fngetCurParkingList()
      } else {
        // 路外开启关闭
        res = await setOutParkingStatus(obj)
        if (res && res.Code === 200) {
          this.$msg.success(res.Message)
        }
        this.fnGetOutParkingList()
      }
    },
    // 查看路内停车场
    lookCurParking (row) {
      this.dialogParkingId = row._id
      this.isCurOrOutPark = 'curPark'
      this.showLookParkDetail = true
    },
    // 查看路外停车场
    lookParking (row) {
      this.dialogParkingId = row._id
      this.isCurOrOutPark = 'outPark'
      this.showLookParkDetail = true
    },
    // 编辑路外停车
    editOutPark (id) {
      this.dialogParkingId = id
      this.showEditOutParking = true
    },
    // 路外通行策略
    parkingAccessStrategy (id) {
      this.showparkingAccessStrategy = true
      this.dialogParkingId = id
    },
    // 路外设备配置
    deviceSetting (row) {
      this.showDeciveSetting = true
      this.dialogParkingId = row._id
      this.dialogParking_name = row.parking_name
    },
    // 弹窗确定
    onConfirm () {
      this.showLookParkDetail = false
    },
    // 展示编辑路内停车场对话框
    batchEdit (id) {
      this.showEditOutParking = true
      this.dialogParkingId = id
      if (this.isCurPark) {
        this.isCurOrOutPark = 'curPark'
      } else {
        this.isCurOrOutPark = 'outPark'
      }
    },
    // 路内设备配置
    devicelLayout (row) {
      this.dialogParkingId = row._id
      this.dialogParking_name = row.parking_name
      this.showCurDevicelLayout = true
      // this.$router.push({
      //   path: '/device-layout',
      //   query: {
      //     parking_id: row._id,
      //     parking_name: row.parking_name
      //   }
      // })
    },
    // 路内/路外计费配置
    billingConfiguration (row) {
      this.dialogParkingId = row._id
      this.dialogParking_name = row.parking_name
      this.showBillingConfiguration = true
    },
    // 关闭路内/路外弹窗
    closeDialog () {
      this.showAddEditParking = false
      this.showEditOutParking = false
      this.showparkingAccessStrategy = false
      this.showDeciveSetting = false
      this.showBillingConfiguration = false
      this.showLookParkDetail = false
      this.showCurDevicelLayout = false
      if (this.isCurPark) {
        // 路内弹窗的关闭刷新
        this.fngetCurParkingList()
      } else {
        // 路外弹窗的关闭刷新
        this.fnGetOutParkingList()
      }
    }
  },
  created () {
    this.option.column.push({
      label: '停车场',
      prop: 'parking_name'
    }, {
      label: '所在位置',
      prop: 'position',
      width: 400
    }, {
      label: '总车位数',
      prop: 'berth_num'
    }, {
      label: '剩余车位数',
      prop: 'over_berth_num'
    }, {
      label: '运行状态',
      prop: 'status',
      slot: true
    }, {
      label: '状态',
      prop: 'tcpservice_online',
      slot: true,
      hide: this.isCurPark
    }, {
      label: '创建时间',
      prop: 'create_time',
      slot: true
    })
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    if (this.isCurPark) {
      // 查询路内车场信息
      if (this.queryStatus === 2) {
        this.queryForm = this.deepClone(this.queryObj)
      }
      this.fngetCurParkingList()
    } else {
      // 查询路外信息
      this.fnGetOutParkingList()
    }
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  }
}
</script>

<style scoped lang="less">
.ParkingInformation {
  margin-top: 20px;
}

.el-button {
  width: auto !important;
}

.showLookParking {
  min-height: 70%;

  /deep/.el-dialog {
    min-height: 70%;

  }
}
</style>
